// import CreateCourse from "../../Modules/Admin/Courses/Add/AddCourse";
import ActivitiesStatement from "../../Modules/Admin/ActivitesMarks/Statement";
import LearningAnalysis from "../../Modules/Admin/Analysis/LearningAnalysis";
import {
  AssessmentAnswers,
  AssessmentParticipants,
  AssessmentPreview,
  AssessmentStatistics,
  AssessmentsList,
  AssessmentsStatement,
} from "../../Modules/Admin/Assessments";
import Coupons from "../../Modules/Admin/Coupon/Coupons/Coupons";
import CourseTraineeStatement from "../../Modules/Admin/Departments/DepartmentsTrainees/CourseTraineeStatement/CourseTraineeStatement";
import EvaluationList from "../../Modules/Admin/Evaluation/EvaluationList/EvaluationList";
import FAQListEndUser from "../../Modules/Admin/FAQ/FAQListEndUser";
import CalendarPage from "../../Modules/Calendar/Calendar";
import BoothDetails from "../../Modules/Exhibition/BoothDetails/BoothDetails";
import FAQUser from "../../Modules/FAQ_s/FAQ_User";
import ListGiveGift from "../../Modules/ListGiveGift/ListGiveGift";
import ListMyGift from "../../Modules/ListMyGift/ListMyGift";
import Schedule from "../../Modules/LiveChat/schedule/Schedule";
import LessonCanvas from "../../Modules/Trainer/Course/NewLesson/Canvas/Canvas";
import DiscBoardStatement from "../../Modules/Trainer/Course/discBoard/Statement";
import CourseContentView from "../../Modules/TrainingCourses/TrainingCourseDetails/TrainingCourseContent";
import LessonActivityView from "../../Modules/TrainingCourses/TrainingCourseDetails/TrainingCourseContentLessons/LessonActivityView/LessonActivityView";
import LessonContentView from "../../Modules/TrainingCourses/TrainingCourseDetails/TrainingCourseContentLessons/LessonContentView/LessonContentView";
import LessonSingleContentView from "../../Modules/TrainingCourses/TrainingCourseDetails/TrainingCourseContentLessons/LessonSingleContentView/LessonSingleContentView";
import UnitContentView from "../../Modules/TrainingCourses/TrainingCourseDetails/TrainingCourseContentLessons/UnitContentView/UnitContentView";
import Guidelines from "../../Modules/guideLines/Guidelines";
import SearchPage from "../../Modules/home/SearchPage/SearchPage";
import AdDetials from "../../Modules/trainees/AdvertismentsHome/AdDetials/AdDetials";
import AdvertismentsHome from "../../Modules/trainees/AdvertismentsHome/AdvertismentsHome";
import GiftCourseWrapper from "../../Modules/trainees/GiftCourse/GiftCourseWrapper/GiftCourseWrapper";
import { CoursesList, PartnerCoursesList, Payments } from "../admins";
import { EduGoals, ListQuestionBank } from "../trainers";
import {
  AboutMe,
  AboutUs,
  AcrivitiesMark,
  AddCertificateTemplates,
  AddFaq,
  AddSession,
  AddSponsor,
  AddTraineeByName,
  AllCoursesCurrent,
  AllCoursesStd,
  AllProfile,
  AllProgram,
  AllPrograms,
  AllcoursesFinished,
  Booth,
  CertificateTemplates,
  Certificates,
  ContactUs,
  CouponDetails,
  CourseOutline,
  CourseProfile,
  CourseQuestion,
  DepartmentsTrainees,
  DepartmentsTrainers,
  DiscBoard,
  EditBooth,
  EditCertificateTemplates,
  EditFaq,
  EditProfile,
  EditSession,
  Exam,
  ExamAnswers,
  ExamResult,
  Exhibition,
  ExhibitionPayment,
  FaqList,
  ForgotPassword,
  GeneralSurveys,
  GenericNotFound,
  GiftCoupon,
  HomeCourses,
  Invoice,
  Invoices,
  LessonContent,
  LessonView,
  ListExams,
  MainAuth,
  ModifyTheCourse,
  NewHome,
  PaymentDone,
  PaymentProgram,
  PaymentsDue,
  PersonalInformation,
  PrivacyPolicy,
  PrivateProfile,
  Program,
  PublicCoursesList,
  ReceivedGift,
  RefundRequests,
  RegisterProgram,
  ResetPassword,
  SendGift,
  SendIpBlockedRequest,
  ShowCertificate,
  Sponsor,
  StatisticsBooth,
  SurveyQuestions,
  SurveyView,
  TablePayment,
  TermsAndConditions,
  Trainee,
  TraineeCertificates,
  TrainerDetails,
  Trainers,
  TrainersCreateCourses,
  TrainingCourseContentLessons,
  TrainingCoursePayment,
  TrainingCourseProfile,
  TrainingCoursesDetailsProvider,
  TrainingPayFirst,
  // TrainingPayFourth,
  TrainingPaySecond,
  TrainingPayThird,
  UnityIframe,
  Wallet,
  ZoomInvoice,
  chat,
  verifyEmailPage,
} from "./";

const is_loggedIn = localStorage.getItem("token") ? true : false;
let routesMain;
if (is_loggedIn) {
  routesMain = [
    {
      component: () => <DiscBoard type="lesson" role="trainee" />,
      path: "/course/:courseId/activity/:id",
      exact: true,
    },
    {
      component: (props) => (
        <CourseTraineeStatement {...props} role={"partner"} />
      ),
      path: "/partner/course/:courseId/trainees/:id",
      exact: true,
    },
    {
      component: () => <DiscBoard role="partner" />,
      path: "/partner/course/:courseId/discussion-board/:id",
      exact: true,
    },
    {
      component: () => <DiscBoard role="partner" />,
      path: "/partner/course/:courseId/discussion-board",
      exact: true,
    },
    {
      component: () => <DiscBoard role="partner" type="lesson" />,
      path: "/partner/course/:courseId/activity/:id",
      exact: true,
    },
    {
      component: LessonCanvas,
      path: "/course/:courseId/lesson/:id",
      exact: true,
    },
    {
      component: () => <LessonCanvas isPartner={true} role={"partner"} />,
      path: "/partner/course/:courseId/lesson/:id",
      exact: true,
    },
    {
      component: () => <CourseProfile role="partner" inProgramCourses={true} />,
      path: "/partner/program/:programId/course/:id",
      exact: true,
    },
    {
      component: (props) => (
        <CourseTraineeStatement
          {...props}
          role={"partner"}
          backPath="programs-history"
          programCourses={true}
        />
      ),
      path: "/partner/programs-history/program/:programId/course/:courseId/trainees/:id",
      exact: true,
    },
    {
      component: (props) => (
        <CourseTraineeStatement
          {...props}
          role={"partner"}
          programCourses={true}
        />
      ),
      path: "/partner/programs/program/:programId/course/:courseId/trainees/:id",
      exact: true,
    },
    { component: NewHome, path: "/", exact: true },
    {
      component: MainAuth,
      path: "/register",
      exact: true,
    },
    // {
    //     component: localStorage.getItem('token') ? PrivateProfile : MainAuth,
    //     path: "/login",
    //     exact: true,
    // },
    {
      component: MainAuth,
      path: "/email-verification",
      exact: true,
    },
    {
      component: Trainers,
      path: "/trainers",
      exact: true,
    },
    {
      component: ContactUs,
      path: "/contactUs",
      exact: true,
    },
    {
      component: () => <TrainingCoursesDetailsProvider backPath="program" />,
      path: "/training-programs/:programID/details/course/:id/details",
      exact: true,
    },
    {
      component: AboutUs,
      path: "/about-us",
      exact: true,
    },
    {
      component: TermsAndConditions,
      path: "/terms-and-conditions",
      exact: true,
    },
    {
      component: Guidelines,
      path: "/guidelines",
      exact: true,
    },
    {
      component: PrivacyPolicy,
      path: "/privacy-policy",
      exact: true,
    },
    {
      component: () => (
        <TrainingCoursesDetailsProvider backPath="training-courses" />
      ),
      path: "/training-courses/:id/details",
      exact: true,
    },
    {
      component: () => <TrainingCoursesDetailsProvider backPath="content" />,
      path: "/training-courses/:id/content/details",
      exact: true,
    },
    {
      component: () => (
        <TrainingCoursesDetailsProvider isProgram={true} backPath="content" />
      ),
      path: "/training-programs/:id/content/details",
      exact: true,
    },
    {
      component: () => (
        <TrainingCoursesDetailsProvider backPath="received-coupons" />
      ),
      path: "/private-profile/received-coupons/course/:id/details",
      exact: true,
    },
    {
      component: () => (
        <TrainingCoursesDetailsProvider
          backPath="received-coupons"
          isProgram={true}
        />
      ),
      path: "/private-profile/received-coupons/program/:id/details",
      exact: true,
    },
    {
      component: () => (
        <TrainingCoursesDetailsProvider backPath="received-coupons-programCourses" />
      ),
      path: "/private-profile/received-coupons/program/:programID/course/:id/details",
      exact: true,
    },

    {
      component: SearchPage,
      path: "/home-search",
      exact: true,
    },
    {
      component: () => (
        <TrainingCoursesDetailsProvider
          isProgram={true}
          backPath="training-programs"
        />
      ),
      path: "/training-programs/:id/details",
      exact: true,
    },
    {
      component: TrainingCoursePayment,
      path: "/course/:id/payment",
      exact: true,
    },
    {
      component: () => <TrainingCoursePayment isProgram={true} />,
      path: "/program/:id/payment",
      exact: true,
    },
    {
      component: () => (
        <TrainingCoursePayment isProgram={true} isGiftPayment={true} />
      ),
      path: "/program/:id/gift-payment/:giftId",
      exact: true,
    },
    {
      component: () => <TrainingCoursePayment isGift={true} />,
      path: "/course/:id/gift",
      exact: true,
    },
    {
      component: () => <TrainingCoursePayment isProgram={true} isGift={true} />,
      path: "/program/:id/gift",
      exact: true,
    },
    {
      component: () => <GiftCoupon type="course" />,
      path: "/course/:id/gift-coupon",
      exact: true,
    },
    {
      component: () => <GiftCoupon type="program" />,
      path: "/program/:id/gift-coupon",
      exact: true,
    },
    // {
    //   component: TrainingCourseContent,
    //   path: "/training-courses/:id/content",
    //   exact: true,
    // },
    {
      component: CourseContentView,
      path: "/training-courses/:id/content",
      exact: true,
    },
    {
      component: () => <CourseContentView courseFromProgram={true} />,
      path: "/training-courses/:programId/course/:id/content",
      exact: true,
    },
    {
      component: TrainingCourseContentLessons,
      path: "/training-courses/:id/content/:chapterId/:supChapterId/lessons",
      exact: true,
    },
    {
      component: UnitContentView,
      path: "/training-courses/:courseId/chapter/:chapterId/unit/:unitId",
      exact: true,
    },
    {
      component: LessonContentView,
      path: "/training-courses/:courseId/chapter/:chapterId/unit/:unitId/lesson/:lessonId",
      exact: true,
    },
    {
      component: LessonActivityView,
      path: "/training-courses/:id/content/:chapterId/:supChapterId/lesson/:lessonId/activity/:activityId",
      exact: true,
    },
    {
      component: LessonSingleContentView,
      path: "/training-courses/:id/content/:chapterId/:supChapterId/lesson/:lessonId/content/:contentId",
      exact: true,
    },
    {
      component: TrainerDetails,
      path: "/trainers/trainer-details/:id",
      exact: true,
    },
    {
      component: Trainee,
      path: "/trainee",
      exact: true,
    },
    {
      component: PrivateProfile,
      path: "/private-profile",
      exact: true,
    },
    {
      component: (props) => <LearningAnalysis {...props} role={"trainee"} />,
      path: "/private-profile/learning-analysis",
      exact: true,
    },
    {
      component: () => <DiscBoard role="partner" />,
      path: "/course/:courseId/discussion-board/:id",
      exact: true,
    },
    {
      component: () => <DiscBoard role="trainee" />,
      path: "/course/:courseId/discussion-board/:id",
      exact: true,
    },
    {
      component: () => <CertificateTemplates />,
      path: "/certificateTemplates",
      exact: true,
    },
    {
      component: () => <AddCertificateTemplates />,
      path: "/certificateTemplates/add",
      exact: true,
    },
    {
      component: () => <AddCertificateTemplates />,
      path: "/certificateTemplates/:id/add",
      exact: true,
    },
    {
      component: () => <CertificateTemplates />,
      path: "/certificateTemplates/:id",
      exact: true,
    },
    {
      component: () => <CertificateTemplates isProgram={true} />,
      path: "/program/certificateTemplates/:id",
      exact: true,
    },
    {
      component: () => <EditCertificateTemplates />,
      path: "/certificateTemplates/edit/:id",
      exact: true,
    },
    {
      component: () => <TraineeCertificates />,
      path: "/trainee/:id/certificates",
      exact: true,
    },
    {
      component: () => <TraineeCertificates />,
      path: "/trainee/:id/:source/certificates",
      exact: true,
    },
    {
      component: CourseProfile,
      path: "/partner/course/:id",
      exact: true,
    },
    {
      component: (props) => <EduGoals role={"partner"} />,
      path: "/partner/course/educational-goals/:id",
      exact: true,
    },
    {
      component: () => (
        <AssessmentsList quizable={"chapter"} role={"partner"} />
      ),
      path: "/partner/course/:courseId/chapter/:quizableId/assessments",
      exact: true,
    },
    {
      component: () => <AssessmentsList quizable={"lesson"} role={"partner"} />,
      path: "/partner/course/:courseId/lesson/:quizableId/assessments",
      exact: true,
    },
    {
      component: () => <AssessmentsStatement role={"partner"} />,
      path: "/partner/course/:courseId/assessments",
      exact: true,
    },
    {
      component: () => <EvaluationList quizable={"lesson"} role={"partner"} />,
      path: "/partner/course/:courseId/lesson/:quizableId/assessment/evaluation",
      exact: true,
    },
    {
      component: () => <EvaluationList quizable={"chapter"} role={"partner"} />,
      path: "/partner/course/:courseId/chapter/:quizableId/assessment/evaluation",
      exact: true,
    },
    {
      component: () => (
        <AssessmentStatistics quizable={"chapter"} role={"partner"} />
      ),
      path: "/partner/course/:courseId/chapter/:quizableId/assessment/:quizId/statistics",
      exact: true,
    },
    {
      component: () => (
        <AssessmentStatistics quizable={"lesson"} role={"partner"} />
      ),
      path: "/partner/course/:courseId/lesson/:quizableId/assessment/:quizId/statistics",
      exact: true,
    },
    {
      component: (props) => <ListQuestionBank role={"partner"} />,
      path: "/partner/course/:courseId/question-bank",
      exact: true,
    },
    {
      component: () => (
        <AssessmentParticipants quizable={"chapter"} role={"partner"} />
      ),
      path: "/partner/course/:courseId/chapter/:quizableId/assessment/:quizId/participants",
      exact: true,
    },
    {
      component: () => (
        <AssessmentParticipants quizable={"lesson"} role={"partner"} />
      ),
      path: "/partner/course/:courseId/lesson/:quizableId/assessment/:quizId/participants",
      exact: true,
    },
    {
      component: () => (
        <AssessmentAnswers quizable={"chapter"} role={"partner"} />
      ),
      path: "/partner/course/:courseId/chapter/:quizableId/assessment/:quizId/participants/answers/:id",
      exact: true,
    },
    {
      component: () => (
        <AssessmentAnswers quizable={"lesson"} role={"partner"} />
      ),
      path: "/partner/course/:courseId/lesson/:quizableId/assessment/:quizId/participants/answers/:id",
      exact: true,
    },
    {
      component: () => (
        <AssessmentPreview quizable={"chapter"} role={"partner"} />
      ),
      path: "/partner/course/:courseId/chapter/:quizableId/assessment/:quizId",
      exact: true,
    },
    {
      component: () => (
        <AssessmentPreview quizable={"lesson"} role={"partner"} />
      ),
      path: "/partner/course/:courseId/lesson/:quizableId/assessment/:quizId",
      exact: true,
    },
    {
      component: (props) => <SurveyView role={"partner"} preview={true} />,
      path: "/partner/course/:targetCourseId/lesson/:targetLessonId/survey/preview/:id",
      exact: true,
    },
    {
      component: () => (
        <PartnerCoursesList
          role="partner"
          status="Closed"
          isHistoryCourses={true}
        />
      ),
      path: "/partner/courses-history",
      exact: true,
    },
    {
      component: () => <PartnerCoursesList role="partner" status="Published" />,
      path: "/partner/courses",
      exact: true,
    },
    {
      component: () => (
        <PartnerCoursesList
          isProgram={true}
          role="partner"
          status="Closed"
          isHistoryPrograms={true}
        />
      ),
      path: "/partner/programs-history",
      exact: true,
    },
    {
      component: () => (
        <CoursesList
          role="partner"
          programCourses={true}
          backPath="programs-history"
          isHistoryPrograms={true}
          previewRoute={"inprogram"}
        />
      ),
      path: "/partner/programs-history/program/:id/courses",
      exact: true,
    },
    {
      component: () => <AcrivitiesMark role="partner" />,
      path: "/partner/course/lesson/:id/:activityId/activities-grades",
      exact: true,
    },
    {
      component: () => (
        <PartnerCoursesList
          isProgram={true}
          role="partner"
          status="Published"
        />
      ),
      path: "/partner/programs",
      exact: true,
    },
    {
      component: () => (
        <CoursesList
          role="partner"
          programCourses={true}
          previewRoute="inprogram"
        />
      ),
      path: "/partner/program/:id/courses",
      exact: true,
    },
    {
      component: (props) => (
        <CourseTraineeStatement
          {...props}
          role={"partner"}
          backPath="courses-history"
        />
      ),
      path: "/partner/courses-history/course/:courseId/trainees/:id",
      exact: true,
    },
    {
      component: () => <PublicCoursesList type="courses" status="Published" />,
      path: "/private-profile/courses",
      exact: true,
    },
    {
      component: () => <PublicCoursesList type="courses" status="Closed" />,
      path: "/private-profile/courses-history",
      exact: true,
    },
    {
      component: () => <PublicCoursesList type="programs" status="Closed" />,
      path: "/private-profile/programs-history",
      exact: true,
    },
    {
      component: () => <PublicCoursesList type="programs" status="Published" />,
      path: "/private-profile/programs",
      exact: true,
    },
    {
      component: CourseProfile,
      path: "/course/:id",
      exact: true,
    },
    {
      component: DepartmentsTrainees,
      path: "/course/:id/trainees",
      exact: true,
    },
    {
      component: () => <DepartmentsTrainees backPath="courses-history" />,
      path: "/courses-history/course/:id/trainees",
      exact: true,
    },
    {
      component: () => <DepartmentsTrainers withManager={true} />,
      path: "/course/:id/trainers",
      exact: true,
    },
    {
      component: () => (
        <DepartmentsTrainers backPath="courses-history" withManager={true} />
      ),
      path: "/courses-history/course/:id/trainers",
      exact: true,
    },
    {
      component: () => <Payments type="course" />,
      path: "/course/:id/trainees-payments",
      exact: true,
    },
    {
      component: () => (
        <DepartmentsTrainers isProgram={true} withManager={true} />
      ),
      path: "/program/:id/trainers",
      exact: true,
    },
    {
      component: () => (
        <DepartmentsTrainers
          isProgram={true}
          backPath="programs-history"
          withManager={true}
        />
      ),
      path: "/programs-history/program/:id/trainers",
      exact: true,
    },
    {
      component: () => <DepartmentsTrainees isProgram={true} />,
      path: "/program/:id/trainees",
      exact: true,
    },
    {
      component: () => (
        <DepartmentsTrainees isProgram={true} backPath="programs-history" />
      ),
      path: "/programs-history/program/:id/trainees",
      exact: true,
    },
    {
      component: () => <DepartmentsTrainees programCourses={true} />,
      path: "/program/:id/course/:courseId/trainees",
      exact: true,
    },
    {
      component: () => (
        <DepartmentsTrainees
          programCourses={true}
          backPath="programs-history"
        />
      ),
      path: "/programs-history/program/:id/course/:courseId/trainees",
      exact: true,
    },
    {
      component: () => <DepartmentsTrainers programCourses={true} />,
      path: "/program/:id/course/:courseId/trainers",
      exact: true,
    },
    {
      component: () => (
        <DepartmentsTrainers
          programCourses={true}
          backPath="programs-history"
        />
      ),
      path: "/programs-history/program/:id/course/:courseId/trainers",
      exact: true,
    },
    {
      component: () => <Payments type="program" isProgram={true} />,
      path: "/program/:id/trainees-payments",
      exact: true,
    },
    {
      component: Certificates,
      path: "/private-profile/certificates",
      exact: true,
    },
    {
      component: RefundRequests,
      path: "/private-profile/refund-requests",
      exact: true,
    },
    {
      component: AboutMe,
      path: "/private-profile/about-me",
      exact: true,
    },
    {
      component: SendGift,
      path: "/private-profile/sent-gift",
      exact: true,
    },
    {
      component: PaymentsDue,
      path: "/private-profile/sent-gift/payments-due",
      exact: true,
    },
    {
      component: PaymentDone,
      path: "/private-profile/sent-gift/payments-done",
      exact: true,
    },
    {
      component: ReceivedGift,
      path: "/private-profile/received-gift",
      exact: true,
    },
    {
      component: () => <Coupons type="sendCoupon" />,
      path: "/private-profile/sent-coupons",
      exact: true,
    },
    {
      component: () => <Coupons type="receivedCoupon" />,
      path: "/private-profile/received-coupons",
      exact: true,
    },
    {
      component: () => <CouponDetails type="sendCoupon" />,
      path: "/private-profile/sent-coupons/:id",
      exact: true,
    },
    {
      component: Invoices,
      path: "/private-profile/invoices",
      exact: true,
    },
    {
      component: Wallet,
      path: "/private-profile/wallet",
      exact: true,
    },
    {
      component: AllCoursesStd,
      path: "/study-courses",
      exact: true,
    },
    {
      component: AllPrograms,
      path: "/study-programs",
      exact: true,
    },
    {
      component: AllCoursesCurrent,
      path: "/courses",
      exact: true,
    },
    {
      component: AllcoursesFinished,
      path: "/finished-courses",
      exact: true,
    },
    {
      component: EditProfile,
      path: "/edit-profile",
      exact: true,
    },
    {
      component: ListGiveGift,
      path: "/gift-list",
      exact: true,
    },
    {
      component: ListMyGift,
      path: "/my-gift-list",
      exact: true,
    },
    {
      component: ResetPassword,
      path: "/update-password",
      exact: true,
    },
    {
      component: AllProfile,
      path: "/all-profile",
      exact: false,
    },
    {
      component: PersonalInformation,
      path: "/personal-info",
      exact: true,
    },
    {
      component: ShowCertificate,
      path: "/show-certificate",
      exact: true,
    },
    {
      component: TrainersCreateCourses,
      path: "/trainers-create-courses",
      exact: true,
    },
    {
      component: LessonView,
      path: "/training-courses/:courseId/lesson/:id",
      exact: true,
    },
    // {
    //   component: CreateCourse,
    //   path: "/create-new-course",
    //   exact: true,
    // },
    {
      component: ModifyTheCourse,
      path: "/modify-the-course",
      exact: true,
    },
    {
      component: TrainingPayFirst,
      path: "/TrainingCourses/training-de/1",
      exact: false,
    },
    {
      component: TrainingPaySecond,
      path: "/TrainingCourses/training-de/2",
      exact: true,
    },
    {
      component: TrainingPayThird,
      path: "/TrainingCourses/training-de/3",
      exact: true,
    },
    // {
    //   component: TrainingPayFourth,
    //   path: "/TrainingCourses/training-de/4",
    //   exact: true,
    // },
    {
      component: verifyEmailPage,
      path: "/verifyEmail/:id",
      exact: true,
    },
    {
      component: CourseOutline,
      path: "/course-outline",
      exact: true,
    },
    {
      component: LessonContent,
      path: "/course-outline/add-new-lesson",
      exact: true,
    },
    {
      component: CourseQuestion,
      path: "/course-outline/add-new-lesson/add-question",
      exact: true,
    },
    {
      component: TrainingCourseProfile,
      path: "/training-courses/:id/profile",
      exact: true,
    },
    {
      component: SendIpBlockedRequest,
      path: "/send-requests",
      exact: true,
    },
    {
      component: GiftCourseWrapper,
      path: "/gift-course/:id",
      exact: true,
    },
    {
      component: AllProgram,
      path: "/all-program",
      exact: true,
    },
    {
      component: Program,
      path: "/program/:id",
      exact: true,
    },
    {
      component: RegisterProgram,
      path: "/register-program/:id",
      exact: true,
    },
    {
      component: PaymentProgram,
      path: "/program/:id/payment/:paymentId",
      exact: true,
    },
    {
      component: TablePayment,
      path: "/table-payment/:id",
      exact: true,
    },
    {
      component: GenericNotFound,
      path: "/page-not-found",
      exact: false,
    },
    {
      component: Sponsor,
      path: "/sponsor",
      exact: true,
    },
    {
      component: AddSponsor,
      path: "/sponsor/addsponsor",
      exact: true,
    },
    {
      component: AddTraineeByName,
      path: "/sponsor/addtraineebyname",
      exact: true,
    },
    {
      component: Invoice,
      path: "/invoice",
      exact: true,
    },
    {
      component: AddSession,
      path: "/zoom/add-session/:id",
      exact: true,
    },
    {
      component: ZoomInvoice,
      path: "/lesson/:lessonId/zoom/zoomInvoice/:id",
      exact: true,
    },
    {
      component: EditSession,
      path: "/zoom/edit-session/:id",
      exact: true,
    },
    {
      component: chat,
      path: "/livechat",
      exact: true,
    },
    {
      component: Schedule,
      path: "/livechat/schedule/:id",
      exact: true,
    },

    // {
    //   component: FAQ,
    //   path: "/faq",
    //   exact: true,
    // },
    {
      component: FAQUser,
      path: "/faq/faq-list",
      exact: true,
    },
    {
      component: FAQListEndUser,
      path: "/tickets/faq-list",
      exact: true,
    },
    {
      component: EditFaq,
      path: "/faq/edit/:id",
      exact: true,
    },
    {
      component: AddFaq,
      path: "/faq/add/:id",
      exact: true,
    },
    {
      component: Exam,
      path: "/trainees/Exams/Exam/:id",
      exact: true,
    },
    {
      component: (props) => <Exam {...props} quizable={`chapter`} />,
      path: "/trainees/course/:courseId/chapter/:quizableId/exam/:id",
      exact: true,
    },
    {
      component: (props) => <Exam {...props} quizable={`lesson`} />,
      path: "/trainees/course/:courseId/lesson/:quizableId/exam/:id",
      exact: true,
    },
    {
      component: ListExams,
      path: "/trainees/Exams/ListExams",
      exact: true,
    },
    {
      component: ExamResult,
      path: "/trainees/Exams/ExamResult/:id",
      exact: true,
    },
    {
      component: (props) => <ListExams {...props} quizable={`chapter`} />,
      path: "/trainees/course/:courseId/chapter/:quizableId/exams",
      exact: true,
    },
    {
      component: (props) => <ListExams {...props} quizable={`lesson`} />,
      path: "/trainees/course/:courseId/lesson/:quizableId/exams",
      exact: true,
    },
    {
      component: (props) => (
        <AssessmentsStatement {...props} isTrainee={true} role={"trainees"} />
      ),
      path: "/trainees/course/:courseId/exams",
      exact: true,
    },
    {
      component: (props) => (
        <ActivitiesStatement {...props} role="trainee" isTrainee={true} />
      ),
      path: "/trainees/course/:courseId/activities",
      exact: true,
    },
    {
      component: (props) => (
        <DiscBoardStatement {...props} role="trainee" isTrainee={true} />
      ),
      path: "/trainees/course/:courseId/discussions",
      exact: true,
    },
    {
      component: (props) => (
        <AssessmentsStatement {...props} isTrainee={true} />
      ),
      path: "/trainees/course/:courseId/exams",
      exact: true,
    },
    {
      component: (props) => <ActivitiesStatement {...props} isTrainee={true} />,
      path: "/trainees/course/:courseId/activities",
      exact: true,
    },
    {
      component: (props) => <ExamResult {...props} quizable={`chapter`} />,
      path: "/trainees/course/:courseId/chapter/:quizableId/exam/:quizId/result",
      exact: true,
    },
    {
      component: (props) => <ExamResult {...props} quizable={`lesson`} />,
      path: "/trainees/course/:courseId/lesson/:quizableId/exam/:quizId/result",
      exact: true,
    },
    {
      component: (props) => (
        <AssessmentAnswers {...props} quizable={`chapter`} isTrainee />
      ),
      path: "/trainees/course/:courseId/chapter/:quizableId/exam/:quizId/answers",
      exact: true,
    },
    {
      component: (props) => (
        <AssessmentAnswers {...props} quizable={`lesson`} isTrainee />
      ),
      path: "/trainees/course/:courseId/lesson/:quizableId/exam/:quizId/answers",
      exact: true,
    },
    {
      component: (props) => <SurveyView role={"trainee"} isTrainee={true} />,
      path: "/survey/:id/course/:targetCourseId",
      exact: true,
    },
    {
      component: (props) => <SurveyView role={"partner"} general />,
      path: "/survey/:id/exhibition/:targetExhibitionId",
      exact: true,
    },
    {
      component: GeneralSurveys,
      path: "/general-surveys",
      exact: true,
    },
    {
      component: CalendarPage,
      path: "/calendar",
      exact: true,
    },
    {
      component: (props) => <SurveyQuestions {...props} isPublic />,
      path: "/survey/:id/statistics",
      exact: true,
    },
    {
      component: (props) => <SurveyView role={"partner"} general={true} />,
      path: "/general-surveys/:id",
      exact: true,
    },
    {
      component: Exhibition,
      path: "/Exhibition",
      exact: true,
    },
    {
      component: Booth,
      path: "/exhibition-booth/:id",
      exact: true,
    },
    {
      component: BoothDetails,
      path: "/booth-details/:id",
      exact: true,
    },
    {
      component: UnityIframe,
      path: "/show-exhibition/unity-iframe/:id",
      exact: true,
    },
    {
      component: () => <ExhibitionPayment type="visitor" />,
      path: "/exhibition-payment/visitor/:id",
      exact: true,
    },
    {
      component: () => <ExhibitionPayment type="booth" />,
      path: "/exhibition-payment/booth/:id",
      exact: true,
    },
    {
      component: EditBooth,
      path: "/edit-booth/:id",
      exact: true,
    },
    {
      component: Certificates,
      path: "/certificate/:id",
      exact: true,
    },
    {
      component: StatisticsBooth,
      path: "/statistics-booth/:id",
      exact: true,
    },
    {
      component: ForgotPassword,
      path: "/forgot-password",
      exact: true,
    },
    {
      component: HomeCourses,
      path: "/training-courses",
      exact: true,
    },
    {
      component: () => <HomeCourses isProgram={true} />,
      path: "/training-programs",
      exact: true,
    },
  ];
} else {
  routesMain = [
    { component: NewHome, path: "/", exact: true },
    {
      component: MainAuth,
      path: "/register",
      exact: true,
    },
    // {
    //     component: localStorage.getItem('token') ? PrivateProfile : MainAuth,
    //     path: "/login",
    //     exact: true,
    // },
    {
      component: MainAuth,
      path: "/email-verification",
      exact: true,
    },
    {
      component: () => <TrainingCoursesDetailsProvider backPath="program" />,
      path: "/training-programs/:programID/details/course/:id/details",
      exact: true,
    },
    {
      component: Guidelines,
      path: "/guidelines",
      exact: true,
    },
    {
      component: Trainers,
      path: "/trainers",
      exact: true,
    },
    {
      component: ContactUs,
      path: "/contactUs",
      exact: true,
    },
    {
      component: AboutUs,
      path: "/about-us",
      exact: true,
    },
    {
      component: () => <TrainingCoursesDetailsProvider isProgram={true} />,
      path: "/training-programs/:id/details",
      exact: true,
    },

    {
      component: TermsAndConditions,
      path: "/terms-and-conditions",
      exact: true,
    },
    {
      component: PrivacyPolicy,
      path: "/privacy-policy",
      exact: true,
    },
    {
      component: TrainingCoursesDetailsProvider,
      path: "/training-courses/:id/details",
      exact: true,
    },
    {
      component: CourseContentView,
      path: "/training-courses/:id",
      exact: true,
    },
    {
      component: SearchPage,
      path: "/home-search",
      exact: true,
    },
    {
      component: CourseContentView,
      path: "/training-courses/:id/content",
      exact: true,
    },
    {
      component: () => <CourseContentView courseFromProgram={true} />,
      path: "/training-courses/:programId/course/:id/content",
      exact: true,
    },
    {
      component: TrainingCourseContentLessons,
      path: "/training-courses/:id/content/:chapterId/:supChapterId/lessons",
      exact: true,
    },
    {
      component: UnitContentView,
      path: "/training-courses/:courseId/chapter/:unitId/content",
      exact: true,
    },
    {
      component: LessonContentView,
      path: "/training-courses/:courseId/chapter/:unitId/lesson/:lessonId/content",
      exact: true,
    },
    {
      component: LessonActivityView,
      path: "/training-courses/:id/content/:chapterId/:supChapterId/lesson/:lessonId/activity/:activityId",
      exact: true,
    },
    {
      component: TrainerDetails,
      path: "/trainers/trainer-details/:id",
      exact: true,
    },
    {
      component: Trainee,
      path: "/trainee",
      exact: true,
    },
    {
      component: AllCoursesStd,
      path: "/study-courses",
      exact: true,
    },
    {
      component: AllPrograms,
      path: "/study-programs",
      exact: true,
    },
    {
      component: AllCoursesCurrent,
      path: "/courses",
      exact: true,
    },
    {
      component: HomeCourses,
      path: "/training-courses",
      exact: true,
    },
    {
      component: () => <HomeCourses isProgram={true} />,
      path: "/training-programs",
      exact: true,
    },
    {
      component: AllcoursesFinished,
      path: "/finished-courses",
      exact: true,
    },
    {
      component: AllProfile,
      path: "/all-profile",
      exact: false,
    },
    {
      component: PersonalInformation,
      path: "/personal-info",
      exact: true,
    },
    {
      component: ShowCertificate,
      path: "/show-certificate",
      exact: true,
    },
    {
      component: TrainersCreateCourses,
      path: "/trainers-create-courses",
      exact: true,
    },
    {
      component: verifyEmailPage,
      path: "/verifyEmail/:id",
      exact: true,
    },
    {
      component: CourseOutline,
      path: "/course-outline",
      exact: true,
    },
    {
      component: LessonContent,
      path: "/course-outline/add-new-lesson",
      exact: true,
    },
    {
      component: CourseQuestion,
      path: "/course-outline/add-new-lesson/add-question",
      exact: true,
    },
    {
      component: TrainingCourseProfile,
      path: "/training-courses/:id/profile",
      exact: true,
    },
    {
      component: SendIpBlockedRequest,
      path: "/send-requests",
      exact: true,
    },
    {
      component: GiftCourseWrapper,
      path: "/gift-course/:id",
      exact: true,
    },
    {
      component: AllProgram,
      path: "/all-program",
      exact: true,
    },
    {
      component: Program,
      path: "/program/:id",
      exact: true,
    },
    {
      component: RegisterProgram,
      path: "/register-program/:id",
      exact: true,
    },
    {
      component: PaymentProgram,
      path: "/program/:id/payment/:paymentId",
      exact: true,
    },
    {
      component: TablePayment,
      path: "/table-payment/:id",
      exact: true,
    },
    {
      component: GenericNotFound,
      path: "/page-not-found",
      exact: false,
    },
    {
      component: Sponsor,
      path: "/sponsor",
      exact: true,
    },
    {
      component: AddSponsor,
      path: "/sponsor/addsponsor",
      exact: true,
    },
    {
      component: AddTraineeByName,
      path: "/sponsor/addtraineebyname",
      exact: true,
    },
    {
      component: Invoice,
      path: "/invoice",
      exact: true,
    },
    {
      component: AddSession,
      path: "/zoom/add-session/:id",
      exact: true,
    },
    {
      component: ZoomInvoice,
      path: "/lesson/:lessonId/zoom/zoomInvoice/:id",
      exact: true,
    },
    {
      component: EditSession,
      path: "/zoom/edit-session/:id",
      exact: true,
    },
    {
      component: chat,
      path: "/livechat",
      exact: true,
    },
    {
      component: Schedule,
      path: "/livechat/schedule/:id",
      exact: true,
    },
    {
      component: FaqList,
      path: "/faq/faq-list",
      exact: true,
    },
    {
      component: EditFaq,
      path: "/faq/edit/:id",
      exact: true,
    },
    {
      component: AddFaq,
      path: "/faq/add/:id",
      exact: true,
    },
    {
      component: Exam,
      path: "/trainees/Exams/Exam/:id",
      exact: true,
    },
    {
      component: ListExams,
      path: "/trainees/Exams/ListExams",
      exact: true,
    },
    {
      component: ExamResult,
      path: "/trainees/Exams/ExamResult/:id",
      exact: true,
    },
    {
      component: ExamAnswers,
      path: "/trainees/Exams/ExamAnswers",
      exact: true,
    },
    {
      component: (props) => <SurveyView role={"trainee"} isTrainee={true} />,
      path: "/survey/:id/course/:targetCourseId",
      exact: true,
    },
    {
      component: (props) => <SurveyView role={"partner"} general />,
      path: "/survey/:id/exhibition/:targetExhibitionId",
      exact: true,
    },
    {
      component: GeneralSurveys,
      path: "/general-surveys",
      exact: true,
    },
    {
      component: AdvertismentsHome,
      path: "/advertisments",
      exact: true,
    },
    {
      component: AdDetials,
      path: "/advertisments/:id",
      exact: true,
    },
    {
      component: (props) => <SurveyQuestions {...props} isPublic />,
      path: "/survey/:id/statistics",
      exact: true,
    },
    {
      component: (props) => <SurveyView role={"partner"} general={true} />,
      path: "/general-surveys/:id",
      exact: true,
    },
    {
      component: Exhibition,
      path: "/Exhibition",
      exact: true,
    },
    {
      component: Booth,
      path: "/exhibition-booth/:id",
      exact: true,
    },
    {
      component: UnityIframe,
      path: "/show-exhibition/unity-iframe/:id",
      exact: true,
    },
    {
      component: EditBooth,
      path: "/edit-booth/:id",
      exact: true,
    },
    {
      component: Certificates,
      path: "/certificate/:id",
      exact: true,
    },
    {
      component: StatisticsBooth,
      path: "/statistics-booth/:id",
      exact: true,
    },
    {
      component: ForgotPassword,
      path: "/forgot-password",
      exact: true,
    },
  ];
}
export default routesMain;

// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
// import { CKEditor } from "@ckeditor/ckeditor5-react";
// import { Stack } from "@mui/material";
import { Formik } from "formik";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
// import { NavLink } from "react-router-dom";
// import SunEditor from "suneditor-react";
import { toast } from "react-toastify";
import "suneditor/dist/css/suneditor.min.css"; // Import Sun Editor's CSS File
import { userBlockRequest } from "../../../../Services/api/ipBlocked/ipBlockedProvider";
import HomeIcon from "../../../../assets/icons/home.svg";
import Breadcrumb from "../../../Shared/Components/Breadcrumb/Breadcrumb";
import Input from "../../../Shared/Components/Input/Input";
import SkeletonCard from "../../../Shared/Components/Spinner/SkeletonCard";
import Title from "../../../Shared/Components/Title/Title";

function SendIpBlockedRequest(props) {
  const history = useHistory();
  require("./SendIpBlockedRequest.css");
  const { t } = useTranslation();

  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [ip, setIp] = useState("");
  const [id, setId] = useState("");
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [reason_block, setReason_block] = useState("");
  const [method_block, setMethod_block] = useState("");
  const [lessonId, setLessonId] = useState("");
  const [lesson, setLesson] = useState("");
  const [courseId, setCourseId] = useState("");
  const [course, setCourse] = useState("");
  const [allow_unblock, setAllow_unblock] = useState("");
  const [isRequest, setIsRequest] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [sendRequestsMsg, setSendRequestsMsg] = useState("");

  const handleChangeSendRequestsMsg = (requestsMsg) => {
    setSendRequestsMsg(requestsMsg);
  };

  const query = new URLSearchParams(props.location.search, "search");

  useEffect(() => {
    setId(query.get("id"));
    setIp(query.get("ip"));
    setAllow_unblock(query.get("allow_unblock"));
    setEmail(query.get("email"));
    setUsername(query.get("username"));
    setReason_block(query.get("reason_block"));
    setMethod_block(query.get("method_block"));
    setLessonId(query.get("lesson_id"));
    setCourseId(query.get("course_id"));
    setLesson(query.get("lesson"));
    setCourse(query.get("course"));
    setIsRequest(query.get("isRequest"));
    setIsLoading(false);
  }, []);

  const breadcrumbList = [
    {
      id: "home",
      page: <img src={HomeIcon} alt="" />,
      pagePath: "/",
    },
    // ...(method_block === "course"
    //   ? [
    //       {
    //         id: "courses",
    //         page: t("TrainingCourses"),
    //         pagePath: "/training-courses",
    //       },
    //       {
    //         id: "course",
    //         page: course,
    //         pagePath: `${
    //           role === "trainee"
    //             ? `/training-courses/${courseId}/profile`
    //             : role === "trainer"
    //             ? `/trainer/course/${courseId}`
    //             : `/training-courses`
    //         }`,
    //       },
    //       ...(lessonId !== "undefined" && lessonId !== null && lessonId !== "" && Number(lessonId) !== 0
    //         ? [
    //             {
    //               id: "lesson",
    //               page: lesson,
    //               pagePath: `/training-courses/${courseId}/lesson/${lessonId}`,
    //             },
    //           ]
    //         : []),
    //     ]
    //   : []),
    {
      id: "ipBlocked",
      page: t("block.label.send_request"),
      active: true,
    },
  ];

  return (
    <>
      <div className="container-fluid block-page">
        <div className="row">
          <div className="col-12">
            <div className="admin_flex">
              <div className="py-5">
                <Breadcrumb list={breadcrumbList} />
              </div>
            </div>
            {isLoading == true ? (
              <div
                className="container-fluid"
                style={{
                  marginTop: "10%",
                  height: "60vh",
                }}
              >
                <SkeletonCard />
              </div>
            ) : (
              <div className="main_box main_flex_box">
                <div className="main_inside_box main_input_msg">
                  <Formik
                    initialValues={{
                      ip: ip,
                      name: username === "" ? "" : username,
                      email: email === "" ? "" : email,
                      message: "",
                    }}
                    onSubmit={async (values, { setSubmitting }) => {
                      try {
                        setIsLoading(true);
                        let dataToBeUploaded = {
                          ...values,
                        };

                        dataToBeUploaded.block_id = id;

                        let response = await userBlockRequest(dataToBeUploaded);
                        if (response.status === 200 && response.data.status) {
                          setIsLoading(false);
                          setIsRequest(1);
                          history.push({
                            search: `?id=${id}&ip=${ip}&allow_unblock=${allow_unblock}&email=${email}&username=${username}&reason_block=${reason_block}&method_block=${method_block}&lesson_id=${lessonId}&course_id=${courseId}&lesson=${lesson}&course=${course}&isRequest=${1}`,
                          });
                          toast.success(
                            <span style={{ fontSize: 13, fontWeight: "bold" }}>{t("send_unblock_request_ok")}</span>
                          );
                          setSubmitting(false);
                        } else {
                          alert("Failure");
                          setSubmitting(false);
                        }
                      } catch (err) {
                        setSubmitting(false);
                        alert(
                          err.response.data && err.response.data.msg ? err.response.data.msg : "Failure in service"
                        );
                      }
                    }}
                    validateOnChange={true}
                    validate={(values) => {
                      setHasSubmitted(true);
                      const errors = {};
                      if (!values.ip) {
                        errors.ip = t("crud.errors.required");
                      }

                      if (!values.name) {
                        errors.name = t("crud.errors.required");
                      }

                      if (!values.email) {
                        errors.email = t("crud.errors.required");
                      }

                      if (values.email) {
                        const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
                        if (!emailRegex.test(values.email)) {
                          errors.email = t("crud.errors.invalidEmail");
                        }
                      }

                      if (!values.message) {
                        errors.message = t("crud.errors.required");
                      }
                      if (values.message.length > 191) {
                        errors.message = t("block.errors.massage_max_length");
                      }

                      return errors;
                    }}
                  >
                    {({ values, errors, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) =>
                      allow_unblock ? (
                        <form onSubmit={handleSubmit} className="main_box_form">
                          <div>
                            <div className="admin_flex mb-2">
                              <Title title={reason_block} fontSize={24} />
                            </div>
                            {/* <div className="main_label main_flex_label">
                                  {method_block == "course" && lessonId != null
                                    ? t("block.label.allow_access_lesson")
                                    : method_block == "course"
                                    ? t("block.label.allow_access_course")
                                    : t("block.label.allow_access_platform")}
                                </div> */}
                          </div>

                          <Input
                            label={t("block.label.range")}
                            id="ip"
                            type="text"
                            name="ip"
                            className="main_box_input"
                            onChange={handleChange}
                            placeholder="192.168.1.50"
                            value={values.ip}
                            readonly={true}
                            error={errors.ip}
                          />

                          <Input
                            label={t("block.label.name")}
                            id="name"
                            type="text"
                            name="name"
                            className="main_box_input"
                            onChange={handleChange}
                            placeholder={t("block.placeholders.name")}
                            value={values.name}
                            readonly={username === "" ? false : true}
                            error={errors.name}
                          />

                          <Input
                            label={t("block.label.email")}
                            id="email"
                            type="text"
                            name="email"
                            className="main_box_input"
                            onChange={handleChange}
                            placeholder={t("block.placeholders.email")}
                            value={values.email}
                            readonly={email === "" ? false : true}
                            error={errors.email}
                          />
                          {allow_unblock == 1 ? (
                            <Input
                              label={t("block.label.text")}
                              id="message"
                              type="text"
                              name="message"
                              className="main_box_input"
                              onChange={handleChange}
                              placeholder={t("block.placeholders.text")}
                              value={values.message}
                              error={errors.message}
                              multiline={true}
                              rows={5}
                              readonly={isRequest == 1 ? true : false}
                            />
                          ) : null}
                          {isRequest == 1 ? (
                            <p className={"form-input-error-space"}>{t("block.label.msg_send_2")}</p>
                          ) : allow_unblock == 0 ? (
                            <p className={"form-input-error-space"}>{t("block.label.msg_send_3")}</p>
                          ) : null}

                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            {isRequest == 1 || allow_unblock == 0 ? null : (
                              <button
                                style={{
                                  width: 376,
                                  height: 75,
                                  padding: "4px",
                                  borderRadius: "6px",
                                  display: "inline-block",
                                }}
                                type="submit"
                                disabled={isSubmitting}
                                className="main_box_add_button"
                              >
                                {t("block.label.send")}
                              </button>
                            )}
                          </div>
                        </form>
                      ) : null
                    }
                  </Formik>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default SendIpBlockedRequest;

import { useContext, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { AuthContext } from "../../Services/api/auth/AuthContext";
import { FooterContext } from "../../Services/api/toutorials/FooterContext";
import HomeLogo from "../../assets/icons/newHome.svg";
import HeaderLogo from "../../assets/image/newHeader.png";
import bg from "../../assets/image/newImageAuth.jpeg";
import SkeletonCardOverlay from "../Shared/Components/Spinner/SkeletonCardOverlay";
import { EmailVerification, Login, Register } from "./";
import ForgotPassword from "./forgotPassword/ForgotPassword";
import classes from "./mainAuth.module.css";

function MainAuth(props) {
  const PropsCom = props;
  const pathLocation = PropsCom.location.pathname;
  const authContext = useContext(AuthContext);

  let FormShow = "";
  if (pathLocation === "/login" || pathLocation === "/Login") {
    FormShow = (
      <>
        <Login />
      </>
    );
  } else if (pathLocation === "/register" || pathLocation === "/Register") {
    FormShow = (
      <>
        <Register />
      </>
    );
  } else if (pathLocation === "/email-verification") {
    FormShow = (
      <>
        <EmailVerification />
      </>
    );
  } else if (pathLocation === "/forgot-password") {
    FormShow = (
      <>
        <ForgotPassword />
      </>
    );
  }

  const footerCtx = useContext(FooterContext);

  useEffect(() => {
    footerCtx.setFooter(false);
    return () => {
      footerCtx.setFooter(true);
    };
  }, []);

  return (
    <div
      className={classes.auth_main_box}
      style={{
        minHeight: "calc(100vh)",
      }}
    >
      <div className={classes.blur_image} />
      <div className="container-fluid">
        {authContext.isPerformingAuthenticationOperation ? <SkeletonCardOverlay borderRadius={20} /> : null}
        <div className={classes["auth-card"]} style={{ borderRadius: "4px 0px 0px 4px" }}>
          <div className={classes["auth-inside-card"]} style={{ display: "flex" }}>
            {FormShow}
            <div
              className={classes["image-style"]}
              style={{
                width: "50%",
                position: "relative",
                overflow: "hidden",
                borderRadius: "4px 0px 0px 4px",
              }}
            >
              <div style={{ position: "absolute", left: "15px", top: "15px" }}>
                <NavLink
                  to="/"
                  style={{
                    display: "flex",
                    borderRadius: "50%",
                    padding: "10px",
                    background: "rgba(255, 255, 255)",
                    maxWidth: "40px",
                  }}
                >
                  <img src={HomeLogo} alt="home" style={{ width: "20px", height: "20px" }} />
                </NavLink>
              </div>
              <img
                src={HeaderLogo}
                alt="logo"
                style={{ position: "absolute", top: "20px", width: "60%", right: "10px" }}
              />
              <img src={bg} style={{ width: "100%", height: "100%", objectFit: "cover" }} alt="background" />
            </div>
            {pathLocation !== "/email-verification" && (
              <div className={classes.homeBtn} style={{ position: "absolute", left: "15px", top: "15px" }}>
                <NavLink
                  to="/"
                  style={{
                    display: "flex",
                    borderRadius: "50%",
                    padding: "10px",
                    background: "rgba(255, 255, 255)",
                    maxWidth: "40px",
                  }}
                >
                  <img src={HomeLogo} alt="home" style={{ width: "20px", height: "20px" }} />
                </NavLink>
              </div>
            )}
          </div>
        </div>
        {/* <div className="auth_box">{FormShow}</div> */}
      </div>
    </div>
  );
}

export default MainAuth;

import { Field, Formik } from "formik";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import { getAllCourses } from "../../../../Services/api/CoursesList";
import { getProgramDetails } from "../../../../Services/api/courses/courseProvider";
import {
  blockCountries,
  createBlocked,
  lessonBlock
} from "../../../../Services/api/ipBlocked/ipBlockedProvider";
import HomeIcon from "../../../../assets/icons/home.svg";
import Breadcrumb from "../../../Shared/Components/Breadcrumb/Breadcrumb";
import SkeletonCardOverlay from "../../../Shared/Components/Spinner/SkeletonCardOverlay";

function AddIpBlocked() {
  require("./AddBlock.css");
  const { t } = useTranslation();
  const history = useHistory();

  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [radioValue, setRadioValue] = useState("block");
  const [check, setCheck] = useState(false);
  const [method, setMethod] = useState("site");
  const [range, setRange] = useState("0");
  const [coursesBlock, setCoursesBlock] = useState([]);
  const [programsBlock, setProgramsBlock] = useState([]);
  const [programCoursesBlock, setProgramCoursesBlock] = useState([]);
  const [courses, setCourses] = useState();
  const [programs, setPrograms] = useState();
  const [programCourse, setProgramCourse] = useState();
  const [lessonsBlock, setLessonsBlock] = useState([]);
  const [countries, setCountries] = useState([]);
  const [country, setCountry] = useState("0");
  const [countryName, setCountryName] = useState();
  const [isCoursesLoading, setIsCoursesLoading] = useState(true);

  const checkHandler = () => {
    setCheck(!check);
  };

  const radioValueHandler = (e) => {
    setRadioValue(e.target.value);
  };

  function ValidateIPaddress(IPAddress) {
    let ipformat = IPAddress.match(
      /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/
    );

    return ipformat !== null;
  }

  const getProgramCoursesHandler = async (programId) => {
    try {
      const res = await getProgramDetails(programId);
      if (res.status && res.status == 200 && res.data.status) {
        setProgramCoursesBlock(res.data.program.courses);
        setLessonsBlock([]);
      }
    } catch (err) {
      toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{err.response.data.msg}</span>);
    }
  };

  const getAllCoursesProgramsHandler = async () => {
    try {
      const [coursesRes, programsRes] = await Promise.all([
        getAllCourses({ paginated: 0, perPage: 500, not_in_program: 1, is_program: 0 }),
        getAllCourses({ paginated: 0, perPage: 500, is_program: 1 }),
      ]);

      if (coursesRes.status && coursesRes.status == 200 && coursesRes.data.status) {
        setCoursesBlock(coursesRes?.data?.data?.courses?.data || []);
        setCourses(coursesRes?.data?.data?.courses?.data[0]?.id);
        lessonBlock(coursesRes?.data?.data?.courses?.data[0]?.id)
          .then((res) => {
            if (res.status && res.status == 200 && res.data.status) {
              setLessonsBlock(res.data.lessons);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }

      if (programsRes.status && programsRes.status == 200 && programsRes.data.status) {
        setProgramsBlock(programsRes?.data?.data?.courses?.data || []);
        setPrograms(programsRes?.data?.data?.courses?.data[0]?.id);
        getProgramCoursesHandler(programsRes?.data?.data?.courses?.data[0]?.id);
      }
    } catch (err) {
      toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{err.response.data.msg}</span>);
    } finally {
      setIsCoursesLoading(false);
    }
  };

  useEffect(() => {
    getAllCoursesProgramsHandler();
  }, []);

  useEffect(() => {
    if (range == "country") {
      blockCountries()
        .then((res) => {
          if (res.status && res.status == 200 && res.data.status) {
            setCountries(Object.keys(res.data.countries).map((key) => [key, res.data.countries[key]]));
          }
        })
        .catch((err) => {});
    }
  }, [range]);

  const methodHandler = (e) => {
    e.preventDefault();
    setMethod(e.target.value);

    if (e.target.value == "site") {
      setCourses("0");
      setPrograms("0");
      setLessonsBlock([]);
    } else if (e.target.value == "course") {
      setCourses(coursesBlock[0]?.id);
      setLessonsBlock([]);
    } else if (e.target.value == "program") {
      setPrograms(programsBlock[0]?.id);
      setProgramCourse("0");
      setLessonsBlock([]);
    }
  };

  const handleCountry = (e) => {
    e.preventDefault();
    setCountryName(e.target.options[e.target.selectedIndex].text);
    setCountry(e.target.value);
  };

  const handleRange = (e) => {
    e.preventDefault();
    setRange(e.target.value);
  };

  const courseHandle = (e) => {
    method === "program" ? setProgramCourse(e.target.value) : setCourses(e.target.value);
    lessonBlock(e.target.value)
      .then((res) => {
        if (res.status && res.status == 200 && res.data.status) {
          setLessonsBlock(res.data.lessons);
        }
      })
      .catch((err) => {});
  };

  const programHandle = (e) => {
    setPrograms(e.target.value);
    setProgramCourse("0");
    getProgramCoursesHandler(e.target.value);
  };

  const breadcrumbList = [
    {
      id: "home",
      page: <img src={HomeIcon} alt="" />,
      pagePath: "/",
    },
    {
      id: "IpBlocked_label",
      page: t("admin.label.admin_label"),
      pagePath: "/admin",
    },
    {
      id: "IpBlocked",
      page: t("block.label.block_site"),
      active: true,
    },
  ];

  return (
    <>
      <div className="container-fluid">
        {isCoursesLoading && (method === "course" || method === "program") ? (
          <SkeletonCardOverlay skeletonWidth="100" />
        ) : null}
        <div className="row">
          <div className="col-12">
            <div className="pt-5 pb-5">
              <Breadcrumb list={breadcrumbList} />
            </div>

            <div className="main_box">
              <div className="main_inside_box">
                <Formik
                  initialValues={{
                    name: "",
                    method: method,
                    lesson: null,
                    range: range,
                    reason_block: "",
                    ip: "",
                    start_ip: "",
                    end_ip: "",
                  }}
                  onSubmit={async (values, { setSubmitting }) => {
                    try {
                      let dataToBeUploaded = {
                        ...values,
                      };

                      dataToBeUploaded.action = radioValue;
                      dataToBeUploaded.method = method === "site" ? "site" : "course";
                      dataToBeUploaded.range = range;
                      dataToBeUploaded.course =
                        method == "program" ? (programCourse !== "0" ? programCourse : programs) : courses;
                      dataToBeUploaded.countryCode = country;
                      dataToBeUploaded.country = countryName;
                      dataToBeUploaded.allow_unblock = check == true ? "1" : "0";

                      if (radioValue == "allow") {
                        dataToBeUploaded.allow_unblock = "1";
                      }

                      if (range == "country") {
                        delete dataToBeUploaded.ip;
                        delete dataToBeUploaded.start_ip;
                        delete dataToBeUploaded.end_ip;
                      } else if (range == "ip") {
                        delete dataToBeUploaded.country;
                        delete dataToBeUploaded.countryCode;
                        delete dataToBeUploaded.start_ip;
                        delete dataToBeUploaded.end_ip;
                      } else if (range == "iprange") {
                        delete dataToBeUploaded.country;
                        delete dataToBeUploaded.countryCode;
                        delete dataToBeUploaded.ip;
                      }

                      if (method == "site") {
                        delete dataToBeUploaded.lesson;
                        delete dataToBeUploaded.course;
                      }

                      if (dataToBeUploaded.lesson == "0") {
                        dataToBeUploaded.lesson = null;
                      }

                      let response = await createBlocked(dataToBeUploaded);
                      if (response.status === 201 && response.data.status) {
                        toast.success(<span style={{ fontSize: 13, fontWeight: "bold" }}>{response.data.msg}</span>, {
                          autoClose: 1000,
                        });
                        history.push("/admin/ip-blocked");
                      } else {
                        alert("Failure");
                        setSubmitting(false);
                      }
                    } catch (err) {
                      setSubmitting(false);
                      alert(err.response.data && err.response.data.msg ? err.response.data.msg : "Failure in service");
                    }
                  }}
                  validateOnChange={hasSubmitted}
                  validate={(values) => {
                    setHasSubmitted(true);
                    const errors = {};
                    if (!values.name) {
                      errors.name = t("crud.errors.required");
                    }

                    if (method == "0") {
                      errors.method = t("crud.errors.required");
                    }

                    if (method == "course" && courses == "0") {
                      errors.course = t("crud.errors.required");
                    }

                    if (method == "program" && programs == "0") {
                      errors.program = t("crud.errors.required");
                    }

                    if (range == "0") {
                      errors.range = t("crud.errors.required");
                    }

                    if (range == "country") {
                      if (country == "0") {
                        errors.country = t("crud.errors.required");
                      }
                    } else if (range == "ip") {
                      if (!ValidateIPaddress(values?.ip)) {
                        errors.ip = t("crud.errors.required");
                      }
                    } else if (range == "iprange") {
                      if (!ValidateIPaddress(values?.start_ip)) {
                        errors.start_ip = t("crud.errors.required");
                      }

                      if (!ValidateIPaddress(values?.end_ip)) {
                        errors.end_ip = t("crud.errors.required");
                      }
                    }

                    if (!values.reason_block) {
                      errors.reason_block = t("crud.errors.required");
                    }
                    if (values.reason_block.length > 191) {
                      errors.reason_block = t("block.errors.massage_max_length");
                    }

                    return errors;
                  }}
                >
                  {({ values, errors, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => (
                    <form onSubmit={handleSubmit} className="main_box_form">
                      <div className="main_label">{t("block.label.block_site")}</div>

                      <div className="">
                        <label htmlFor="name" className="tw-text-gray-600 tw-font-medium tw-mb-2">
                          {t("block.label.block_name")}:
                        </label>
                        <input
                          id="name"
                          type="text"
                          name="name"
                          className="main_box_input_add"
                          onChange={handleChange}
                          placeholder={t("block.placeholders.block_name")}
                          value={values.name || ""}
                        />
                        <p className={"form-input-error-space"}>{errors.name ? errors.name : null}</p>
                      </div>

                      <div className="custom_main_width">
                        <label htmlFor="action" className="tw-text-gray-600 tw-font-medium tw-mb-2">
                          {t("block.label.desired_action")}:
                        </label>

                        <div className="admin_ipblock_radio">
                          <div className="">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="inlineRadioOptions"
                              id="inlineRadio7"
                              value="block"
                              checked={radioValue == "block"}
                              onChange={radioValueHandler}
                            />
                            <label className="form-check-label-ipblock" htmlFor="inlineRadio7">
                              {t("block.label.blo")}
                            </label>
                          </div>
                          <div className="">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="inlineRadioOptions"
                              id="inlineRadio2"
                              value="allow"
                              checked={radioValue == "allow"}
                              onChange={radioValueHandler}
                            />
                            <label className="form-check-label-ipblock" htmlFor="inlineRadio2">
                              {t("block.label.Allow_only")}
                            </label>
                          </div>
                        </div>
                      </div>
                      <p className={"form-input-error-space"}></p>

                      {radioValue === "block" ? (
                        <div style={{ display: "flex", gap: "5%" }} className="field-group">
                          <div style={{ width: "30%" }} className="field-item">
                            <label htmlFor="method" className="tw-text-gray-600 tw-font-medium tw-mb-2">
                              {t("block.label.Customize__ban")} :
                            </label>

                            <Field
                              name="CustomizeBan"
                              as="select"
                              className="tw-block tw-w-full tw-border tw-border-gray-200 tw-rounded tw-p-2.5 tw-text-gray-500"
                              // placeholder="نوع الاستبيان"
                              value={method}
                              onBlur={handleBlur}
                              onChange={methodHandler}
                            >
                              <option value="site">{t("block.label.Restrict_platform")}</option>
                              <option value="course">{t("block.label.Restricting_course")}</option>
                              <option value="program">{t("block.label.Restricting_program")}</option>
                            </Field>

                            <p className={"form-input-error-space"}>{errors.method ? errors.method : null}</p>
                          </div>
                          {method == "course" ? (
                            <>
                              <div style={{ width: "30%" }} className="field-item">
                                <label htmlFor="course" className="tw-text-gray-600 tw-font-medium tw-mb-2">
                                  {t("block.label.Choose_course")} :
                                </label>
                                <Field
                                  id="course"
                                  name="course"
                                  as="select"
                                  className="tw-block tw-w-full tw-border tw-border-gray-200 tw-rounded tw-p-2.5 tw-text-gray-500"
                                  value={courses}
                                  onBlur={handleBlur}
                                  onChange={(e) => {
                                    courseHandle(e);
                                    setFieldValue("lesson", "0");
                                  }}
                                >
                                  {coursesBlock.map((course) => {
                                    return (
                                      <option key={course.id} value={course.id}>
                                        {course.name}
                                      </option>
                                    );
                                  })}
                                </Field>

                                <p className={"form-input-error-space"}>{errors.course ? errors.course : null}</p>
                              </div>
                              <div style={{ width: "30%" }} className="field-item">
                                <label htmlFor="lesson" className="tw-text-gray-600 tw-font-medium tw-mb-2">
                                  {t("block.label.choose_lesson")} :
                                </label>
                                <Field
                                  id="lesson"
                                  name="lesson"
                                  as="select"
                                  className="tw-block tw-w-full tw-border tw-border-gray-200 tw-rounded tw-p-2.5 tw-text-gray-500"
                                  // placeholder="نوع الاستبيان"
                                  value={values?.lesson}
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                >
                                  <option value={"0"}>{t("block.label.all")}</option>
                                  {lessonsBlock.map((lesson) => {
                                    return (
                                      <option key={lesson.id} value={lesson.id}>
                                        {lesson.name}
                                      </option>
                                    );
                                  })}
                                </Field>

                                <p className={"form-input-error-space"}>{errors.lesson ? errors.lesson : null}</p>
                              </div>
                            </>
                          ) : method == "program" ? (
                            <>
                              <div style={{ width: "30%" }} className="field-item">
                                <label htmlFor="program" className="tw-text-gray-600 tw-font-medium tw-mb-2">
                                  {t("block.label.Choose_program")} :
                                </label>
                                <Field
                                  id="program"
                                  name="program"
                                  as="select"
                                  className="tw-block tw-w-full tw-border tw-border-gray-200 tw-rounded tw-p-2.5 tw-text-gray-500"
                                  value={programs}
                                  onBlur={handleBlur}
                                  onChange={(e) => {
                                    programHandle(e);
                                    setFieldValue("lesson", "0");
                                  }}
                                >
                                  {programsBlock.map((program) => {
                                    return (
                                      <option key={program.id} value={program.id}>
                                        {program.name}
                                      </option>
                                    );
                                  })}
                                </Field>
                                <p className={"form-input-error-space"}>{errors.program ? errors.program : null}</p>
                              </div>
                              <div style={{ width: "30%" }} className="field-item">
                                <label htmlFor="course" className="tw-text-gray-600 tw-font-medium tw-mb-2">
                                  {t("block.label.Choose_course")} :
                                </label>
                                <Field
                                  id="course"
                                  name="course"
                                  as="select"
                                  className="tw-block tw-w-full tw-border tw-border-gray-200 tw-rounded tw-p-2.5 tw-text-gray-500"
                                  value={programCourse}
                                  onBlur={handleBlur}
                                  onChange={(e) => {
                                    courseHandle(e);
                                    setFieldValue("lesson", "0");
                                  }}
                                >
                                  <option value="0">{t("block.label.all")}</option>
                                  {programCoursesBlock.map((course) => {
                                    return (
                                      <option key={course.id} value={course.id}>
                                        {course.name}
                                      </option>
                                    );
                                  })}
                                </Field>
                              </div>
                              <div style={{ width: "30%" }} className="field-item">
                                <label htmlFor="lesson" className="tw-text-gray-600 tw-font-medium tw-mb-2">
                                  {t("block.label.choose_lesson")} :
                                </label>
                                <Field
                                  id="lesson"
                                  name="lesson"
                                  as="select"
                                  className="tw-block tw-w-full tw-border tw-border-gray-200 tw-rounded tw-p-2.5 tw-text-gray-500"
                                  // placeholder="نوع الاستبيان"
                                  value={values?.lesson}
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                >
                                  <option value={"0"}>{t("block.label.all")}</option>
                                  {lessonsBlock.map((lesson) => {
                                    return (
                                      <option key={lesson.id} value={lesson.id}>
                                        {lesson.name}
                                      </option>
                                    );
                                  })}
                                </Field>
                              </div>
                            </>
                          ) : null}
                        </div>
                      ) : null}

                      <label htmlFor="range" className="tw-text-gray-600 tw-font-medium tw-mb-2">
                        {t("block.label.Ban_scope")} :
                      </label>
                      <div style={{ display: "flex", gap: "5%" }} className="field-group">
                        <div style={{ width: "30%" }} className="field-item">
                          <Field
                            id="range"
                            name="range"
                            as="select"
                            className="tw-block tw-w-full tw-border tw-border-gray-200 tw-rounded tw-p-2.5 tw-text-gray-500"
                            value={range}
                            onBlur={handleBlur}
                            onChange={handleRange}
                          >
                            <option disabled value="0"></option>
                            <option value="country">{t("block.label.country")}</option>
                            <option value="ip">IP</option>
                            <option value="iprange">IP Range</option>
                          </Field>
                          <p className={"form-input-error-space"}>{errors.range ? errors.range : null}</p>
                        </div>
                        {range == "country" ? (
                          <div style={{ width: "65%" }} className="field-item">
                            <Field
                              id="country"
                              name="country"
                              as="select"
                              className="tw-block tw-w-full tw-border tw-border-gray-200 tw-rounded tw-p-2.5 tw-text-gray-500"
                              value={country}
                              onChange={handleCountry}
                              onBlur={handleBlur}
                            >
                              <option disabled value="0"></option>
                              {countries.map((item) => {
                                return <option value={item[0]}>{item[1]}</option>;
                              })}
                            </Field>
                            <p className={"form-input-error-space"}>{errors.country ? errors.country : null}</p>
                          </div>
                        ) : range == "ip" ? (
                          <div style={{ width: "65%" }} className="field-item">
                            <input
                              id="ip"
                              type="text"
                              name="ip"
                              className="main_box_input_add"
                              onChange={handleChange}
                              placeholder="IP Address"
                              value={values?.ip}
                            />
                            <p className={"form-input-error-space"}>{errors.ip ? errors.ip : null}</p>
                          </div>
                        ) : range == "iprange" ? (
                          <div style={{ width: "65%", display: "flex", gap: "2%" }} className="field-item">
                            <div style={{ width: "48%" }}>
                              <input
                                id="start_ip"
                                type="text"
                                name="start_ip"
                                className="main_box_input_add"
                                onChange={handleChange}
                                placeholder="IP Address"
                                value={values?.start_ip}
                              />
                              <p className={"form-input-error-space"}>{errors.start_ip ? errors.start_ip : null}</p>
                            </div>

                            <span className="slash-symbol">-</span>

                            <div style={{ width: "48%" }}>
                              <input
                                id="end_ip"
                                type="text"
                                name="end_ip"
                                className="main_box_input_add"
                                onChange={handleChange}
                                placeholder="IP Address"
                                value={values?.end_ip}
                              />
                              <p className={"form-input-error-space"}>{errors.end_ip ? errors.end_ip : null}</p>
                            </div>
                          </div>
                        ) : null}
                      </div>

                      {radioValue === "allow" ? null : (
                        <div className="row personal_check mb-4">
                          <div className="form-check checkbox">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id="Check1"
                              checked={check}
                              onChange={checkHandler}
                            />
                            <label className="form-check-label" htmlFor="Check1">
                              {t("block.label.Allow_unblocking_request")}
                            </label>
                          </div>
                        </div>
                      )}

                      <div className="">
                        <label htmlFor="reason_block" className="tw-text-gray-600 tw-font-medium tw-mb-2">
                          {t("block.label.msg_block")}
                        </label>
                        <input
                          id="reason_block"
                          type="text"
                          name="reason_block"
                          className="main_box_input_add"
                          onChange={handleChange}
                          placeholder={t("block.placeholders.msg_block")}
                          value={values.reason_block}
                        />

                        <p className={"form-input-error-space"}>{errors.reason_block ? errors.reason_block : null}</p>
                      </div>

                      <div className="d-flex justify-content-end">
                        <button
                          type="submit"
                          disabled={isSubmitting}
                          className="main_box_add_button"
                          style={{
                            width: 250,
                            height: 75,
                            padding: "4px",
                            borderRadius: "6px",
                            display: "inline-block",
                          }}
                        >
                          {t("block.label.add")}
                        </button>
                      </div>
                    </form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddIpBlocked;

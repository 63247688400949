import { useTranslation } from "react-i18next";
import SearchIcon from "../../../../assets/icons/search button.svg";
import MacImage from "../../../../assets/image/mac1.png";
import classes from "./newHomeSearch.module.css";

const NewHomeSearch = () => {
  const { t } = useTranslation();
  return (
    <div className="container-fluid">
      <div className={classes.searchSection}>
        <div className={classes.search}>
          <div className={classes.searchHeading}>
            <h1>{t("home.heading")}</h1>
            <h2>
              {t("home.tebx")}
              <span>.</span>
            </h2>
          </div>
          <p className={classes.searchSubHeading}>{t("home.subHeading")}</p>
        </div>
        <div className={classes.searchImage}>
          <img src={MacImage} alt="MacImage" />
        </div>
      </div>
    </div>
  );
};

export default NewHomeSearch;

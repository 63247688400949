import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import {
  DOC_PDF,
  JPEG_TYPE,
  JPG_TYPE,
  PNG_TYPE,
  acceptDocuments,
  acceptImages,
  fromKbToMb,
} from "../../../../Services/Validator";
import { getListType } from "../../../../Services/api/TypeFile/TypeFileProvider";
import bankIcon from "../../../../assets/icons/bank (1).svg";
import walletVisa from "../../../../assets/icons/wallet.svg";
import Input from "../../../Shared/Components/Input/Input";
import InputFile from "../../../Shared/Components/InputFile/InputFile";
import MainDatePicker from "../../../Shared/Components/MainDatePicker/MainDatePicker";
import PhoneInput from "../../../Shared/Components/PhoneInput/PhoneInput";
import classes from "./trainingCoursePayment.module.css";

export default function CoursePaymentForm({
  handleChange,
  setFieldValue,
  handleSelectPaymentClick,
  selectedBox,
  values,
  errors,
  setTransferDateHours,
  setTransferDateMinutes,
  currentDate,
  dashboard,
  validCoupon,
}) {
  const { t, i18n } = useTranslation();
  const [listTypeFile, setListTypeFile] = useState([]);

  const getListTypeFile = () => {
    const myPromise = new Promise((resolve, reject) => {
      getListType().then(
        (res) => {
          setListTypeFile(res?.data?.data);
          resolve();
        },
        (error) => {
          toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{t("programs.failed_loading")}</span>);
          reject();
        }
      );
    });

    return myPromise;
  };

  useEffect(() => {
    getListTypeFile();
  }, []);

  const getTypeFile = (searchType) => {
    return listTypeFile.filter((file) => file.type === searchType);
  };

  const acceptFiles = () => {
    return `${acceptImages()},${acceptDocuments()}`;
  };

  return (
    <div className={classes["payment-form"]}>
      <div className={classes["payment-box"]}>
        <div className={classes["payment-box-title"]}>{t("payments.payment_method")}</div>
        <div className={classes["payment-box-options"]}>
          <div
            className={classes[`payment-box-option${selectedBox.bankSelected ? "-selected" : ""}`]}
            onClick={() =>
              validCoupon?.mechanism === "2" && Number(validCoupon?.value) === 100
                ? null
                : handleSelectPaymentClick("bank")
            }
          >
            <img src={bankIcon} alt="payment-icon" />
            <h4>{t("payments.bank_transfer")}</h4>
          </div>
          <div
            className={classes[`payment-box-option${selectedBox.walletSelected ? "-selected" : ""}`]}
            onClick={() =>
              validCoupon?.mechanism === "2" && Number(validCoupon?.value) === 100
                ? null
                : handleSelectPaymentClick("wallet")
            }
          >
            <img src={walletVisa} alt="payment-icon" />
            <h4>{t("payments.wallet")}</h4>
            <span className={classes["payment-box-option-amount"]}>
              {dashboard?.total} {t("rs")}
            </span>
          </div>
        </div>
      </div>
      {selectedBox.bankSelected && (
        <div className={classes["payment-fields"]}>
          <div className={classes["payment-field-info"]}>
            <label htmlFor="iban-info">
              <div
                id="iban-info"
                dangerouslySetInnerHTML={{
                  __html: t("payments.info_iban_iau"),
                }}
              />
            </label>
          </div>
          <div className={classes["payment-field"]}>
            <Input
              name="name"
              label={t("payments.account_holder_name")}
              placeholder={t("payments.account_holder_name")}
              type="text"
              onChange={handleChange}
              value={values.name}
              error={errors.name}
              disabled={validCoupon?.mechanism === "2" && Number(validCoupon?.value) === 100}
              required
            />
          </div>
          <div className={classes["payment-field"]}>
            <PhoneInput
              label={t("payments.phone_number")}
              type="text"
              nameInput="phone_number"
              nameSelect="phone_code"
              placeholder={t("payments.phone_number")}
              onChange={handleChange}
              min={0}
              value={values.phone_number}
              selectValue={values.phone_code}
              error={errors.mobile}
              readonly={validCoupon?.mechanism === "2" && Number(validCoupon?.value) === 100}
              required
            />
          </div>
          <div className={classes["payment-field"]}>
            <Input
              name="bank"
              label={t("payments.bank_name")}
              placeholder={t("payments.bank_name")}
              type="text"
              onChange={handleChange}
              value={values.bank}
              error={errors.bank}
              disabled={validCoupon?.mechanism === "2" && Number(validCoupon?.value) === 100}
              required
            />
          </div>
          <div className={classes["payment-field"]}>
            <Input
              name="iban"
              label={t("payments.IBAN_number")}
              placeholder={t("payments.IBAN_number")}
              type="text"
              onChange={handleChange}
              value={values.iban}
              error={errors.iban}
              disabled={validCoupon?.mechanism === "2" && Number(validCoupon?.value) === 100}
              required
            />
          </div>
          <div className={classes["payment-field"]}>
            <MainDatePicker
              label={t(`payments.transfer_date`)}
              name="transfer_date"
              error={errors.transfer_date}
              value={values.transfer_date}
              dayMonthYear={true}
              readOnly={validCoupon?.mechanism === "2" && Number(validCoupon?.value) === 100}
              onChange={(date) => {
                setFieldValue("transfer_date", date);

                let CurrentDate = new Date();
                let CurrentDateFormate = CurrentDate.toISOString().split("T")[0];
                let DataPickerValue = date.toISOString().split("T")[0];
                if (new Date(DataPickerValue).getTime() > new Date(CurrentDateFormate).getTime()) {
                  setTransferDateHours(0);
                  setTransferDateMinutes(0);
                } else {
                  currentDate();
                }
              }}
              minTime={setHours(setMinutes(new Date(), 0), 0)}
              maxTime={setHours(setMinutes(new Date(), 30), 23)}
              required
            />
          </div>
          <div className={classes["payment-field"]}>
            <InputFile
              name="payment_proof"
              type="gallery"
              accept={acceptFiles()}
              label={t("payments.payment_proof_img")}
              disabled={validCoupon?.mechanism === "2" && Number(validCoupon?.value) === 100}
              maxSize={
                getTypeFile
                  ? `${t("general.upload_max_size")} ${fromKbToMb(
                      Math.max(
                        getTypeFile(PNG_TYPE)[0]?.max_size,
                        getTypeFile(JPEG_TYPE)[0]?.max_size,
                        getTypeFile(JPG_TYPE)[0]?.max_size,
                        getTypeFile(DOC_PDF)[0]?.max_size
                      )
                    )} ${t("general.megabyte")}`
                  : t("general.upload_max_size") + " 10 " + t("general.megabyte")
              }
              setFieldValue={setFieldValue}
              onChange={handleChange}
              note={t("general.png_pdf_note")}
              error={errors.payment_proof}
              value={values.payment_proof}
              required
            />
          </div>
          {/* {isGift && (
            <div className={classes["payment-field"]}>
              <Select
                name="trainee_id"
                id="trainee_id"
                options={trainees?.map((trainee) => {
                  return {
                    id: trainee.id,
                    value: trainee.id,
                    label:
                      i18n.language === "ar"
                        ? trainee.name_ar
                        : trainee.name_en,
                  };
                })}
                placeholder={t("gifted_to")}
                onChange={(item) => {
                  setFieldValue("trainee_id", item.value);
                }}
              />
            </div>
          )} */}
        </div>
      )}
    </div>
  );
}

import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  NavLink,
  Redirect,
  Route,
  Switch,
  useLocation,
  withRouter,
} from "react-router-dom";
import withRoot from "../../../../Hoc/withRoot";
import RouteInterceptor from "../../../../Interceptors/RouteInterceptor";
import { AuthContext } from "../../../../Services/api/auth/AuthContext";
import AdminHeader from "../../../Admin/adminHeader/AdminHeader";
import MainAuth from "../../../Auth/MainAuth";
import EmailVerificationRoutes from "../../../EmailVerification/Routes";
import TrainingCourses from "../../../TrainingCourses/TrainingCourses";
import Home from "../../../home/homePage";
import NewHome from "../../../home/newHomePage";
import Admin from "../Admin/Admin";
import Trainer from "../Trainer/Trainer";
import Manager from "../Manager/Manager";
import Links from "./Links";

import Fund from "../../../../Modules/Admin/Financial/fund/fund";
import PeymentRequestsCoursesPrograms from "../../../../Modules/Admin/Financial/peymentRequestsCourses/PeymentRequestsCoursesPrograms";
import Payments from "../../../../Modules/Payments/Payments";
import handleRoutesPaths from "../../../../routes/main/HandlePaths";
import Coupons from "../../../Admin/Coupon/Coupons/Coupons";
import AddCoupon from "../../../Admin/Coupon/addCoupon/addCoupon";
import CouponList from "../../../Admin/Coupon/couponList/CouponList";
import Dashboard from "../../../Admin/Dashboard/Dashboard";
import PercentageTrainersList from "../../../Admin/Financial/PercentageTrainers/PercentageTrainersList";
import ReportFinancial from "../../../Admin/Financial/Report/Report";
import TotalIncomeReport from "../../../Admin/Financial/Report/TotalIncomeReport";
import Offers from "../../../Admin/Financial/offers/Offers";
import AddOffers from "../../../Admin/Financial/offers/add/Add";
import EditOffers from "../../../Admin/Financial/offers/edit/Edit";
import ProgramPaymentDate from "../../../Admin/Financial/programPaymentDate/ProgramPaymentDate";
import RefundList from "../../../Admin/Financial/refund/RefundList";
import AddSpon from "../../../Admin/Financial/sponsor/Add";
import AddNewTrainee from "../../../Admin/Financial/sponsor/AddNewTrainee";
import Sponsor from "../../../Admin/Financial/sponsor/Sponsor";
import Tax from "../../../Admin/Financial/tax/Tax";
import AddTax from "../../../Admin/Financial/tax/add/Add";
import EditTax from "../../../Admin/Financial/tax/edit/Edit";
import UserWallet from "../../../Admin/Financial/userWallet/UserWallet";
import SendIpBlockedRequest from "../../../Admin/IpBlocked/SendIpBlockedRequest/SendIpBlockedRequest";
import AddTicket from "../../../Admin/Tickets/Add/Add";
import ListTickets from "../../../Admin/Tickets/List/List";
import ViewTicket from "../../../Admin/Tickets/View/View";
import BankTransfers from "../../../Admin/bankTransfers/bankTransfers";
import Invoice from "../../../Sponsor/Invoice/Invoice";
import RateTicket from "../Tickets/Rate/Rate";
import AddSponsor from "./../../../Sponsor/AddSponsor";
import Sponsors from "./../../../Sponsor/Sponsors";
import AddTraineeByEmail from "./../../../Sponsor/addTrainee/AddTraineeByEmail";
import AddTraineeByName from "./../../../Sponsor/addTrainee/AddTraineeByName";
import AccountantProvider from "./../accountant/AccountantContext";

import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { usePusherContext } from "../../../../Contexts/PusherContext";
import { getProfileInfo } from "../../../../Services/api/profile/profileProvider";
import NotificationSound from "../../../../assets/sounds/notification.mp3";
import {
  CouponDetails,
  CreateCoupon,
  EditCoupon,
  GenericNotFound,
  ProgramPaymentsReport,
} from "../../../../routes/admins";
import {
  AboutUs,
  ContactUs,
  Exhibition,
  FaqList,
  ForgotPassword,
  HomeCourses,
  PrivacyPolicy,
  TermsAndConditions,
  Trainers,
  TrainingCoursesDetailsProvider,
  UnityIframe,
} from "../../../../routes/main";

import FAQListEndUser from "../../../Admin/FAQ/FAQListEndUser";
import ProgramPaymentListFinancial from "../../../Admin/Financial/programPaymentDate/ProgramPaymentList";
import EditRefundSetting from "../../../Admin/Financial/refundSetting/Edit/Edit";
import RefundSettingList from "../../../Admin/Financial/refundSetting/RefundSettingList";
import AddRefundSetting from "../../../Admin/Financial/refundSetting/add/Add";
import WalletDetails from "../../../Admin/Financial/userWallet/walletDetails/walletDetails";
import { default as SurveyQuestions } from "../../../Admin/survey/questions/List";
import AllRefunds from "../../../AllRefunds/AllRefunds";
import Certificates from "../../../Certificates/Certificates";
import FAQUser from "../../../FAQ_s/FAQ_User";
import FinancialReport from "../../../FinancialReport/FinancialReport";
import InvoiceList from "../../../InvoiceList/InvoiceList";
import MySponsorShip from "../../../Partner/MySponsorShip/MySponsorShip";
import AllPrograms from "../../../Programs/AllProgram/AllProgram";
import purchasingOperations from "../../../PurchasingOperations/PurchasingOperations";
import CoursesOffered from "../../../Trainer/CoursesOffered/CoursesOffered";
import OverView from "../../../Trainer/OverView/OverView";
import TrainerReport from "../../../Trainer/TrainerReport/TrainerReport";
import TrainerInfoDetails from "../../../TrainersInfoDetails/TrainersInfoDetails";
import Wallet from "../../../Wallet/Wallet";
import SearchPage from "../../../home/SearchPage/SearchPage";
import TraineeReport from "../../../trainees/TraineeReport/TraineeReport";
import PrivateProfile from "../../../trainees/privateProfile/privateProfile";
import SurveyView from "../../../trainees/survey/Survey";
import Surveys from "../../../trainees/survey/Surveys";
import SearchInput from "./SearchInput";
import Guidelines from "../../../guideLines/Guidelines";

function MainHeader(props) {
  require("./header.css");
  const { t, i18n } = useTranslation();
  const { pusher } = usePusherContext();

  useEffect(() => {
    const channel = pusher.subscribe(
      `ticket-channel-${localStorage.getItem("userId")}`
    );
    channel.bind("App\\Events\\TicketEvent", (data) => {
      let audio = new Audio(NotificationSound);
      audio.play();
      toast.warning(
        <span style={{ fontSize: 12, fontWeight: "bold" }}>
          {i18n.language === "en" ? data.message_en : data.message_ar}
        </span>
      );
    });

    return () => {
      pusher.unsubscribe(`ticket-channel-${localStorage.getItem("userId")}`);
    };
  }, []);

  useEffect(() => {
    const channel = pusher.subscribe(
      `comment-channel-${localStorage.getItem("userId")}`
    );
    channel.bind("App\\Events\\CommentEvent", (data) => {
      let audio = new Audio(NotificationSound);
      audio.play();
      toast.warning(
        <span style={{ fontSize: 12, fontWeight: "bold" }}>
          {i18n.language === "en" ? data.message_en : data.message_ar}
        </span>
      );
    });

    return () => {
      pusher.unsubscribe(`comment-channel-${localStorage.getItem("userId")}`);
    };
  }, []);

  const authContext = useContext(AuthContext);
  const [navAction, setNavAction] = useState(false);
  const [chatAllowed, setChatAllowed] = useState();
  const [completedProfile, setCompletedProfile] = useState();
  let is_logged_in = localStorage.getItem("token") === null ? false : true;
  const history = useHistory();
  const navActionHandler = () => {
    setNavAction(!navAction);
  };
  const { livechat_setting } = useSelector((state) => state.livechatReducer);
  const query = new URLSearchParams(props.location.search, "search");

  let str = props.location.pathname;
  const locationStr = str.split("/");

  let header = "";

  const listenToScroll = () => {
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;

    const scrolled = winScroll;

    if (document.getElementById("tajah-target-header")) {
      if (scrolled >= 111) {
        document
          .getElementById("tajah-target-header")
          .classList.add("navbar-fixed-top");
      } else {
        document
          .getElementById("tajah-target-header")
          .classList.remove("navbar-fixed-top");
      }
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", listenToScroll);
    // cleanup this component
    return () => {
      window.removeEventListener("scroll", listenToScroll);
    };
  }, []);

  useEffect(() => {
    if (completedProfile === undefined && is_logged_in) {
      getProfileInfo()
        .then((res) => {
          if (res.status && res.status === 201 && res.data.status) {
            localStorage.setItem(
              "completed_profile",
              res.data.response.updatePercentage === 100 ? "true" : "false"
            );
            setCompletedProfile(
              res.data.response.updatePercentage === 100 ? true : false
            );
          }
        })
        .catch((err) => {});
    }
  }, [window.location.pathname, is_logged_in]);

  const [width, setWidth] = useState(0);

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [setWidth]);
  const location = useLocation();

  useEffect(() => {
    if (
      is_logged_in &&
      completedProfile !== undefined &&
      localStorage.getItem("completed_profile") !== "true" &&
      window.location.pathname !== "/edit-profile"
    ) {
      history.push("/edit-profile");
    }
  }, [is_logged_in, completedProfile, window.location.pathname]);

  useEffect(() => {
    if (livechat_setting.status === 200) {
      setChatAllowed(livechat_setting.data.setting.chat_allowed);
    }
  }, [livechat_setting]);

  if (locationStr[1] !== "admin" && locationStr[1] !== "accountant") {
    authContext.setInAdminPanel(false);
    header = (
      <>
        <Links query={query.get("method_block")} chatAllowed={chatAllowed} />

        {width > 1200 && (
          <div
            id={"tajah-target-header"}
            className={
              query.get("method_block") === "site"
                ? `header_links_none`
                : `header_links`
            }
          >
            <div className={`container-fluid flex-box header_links_container`}>
              <NavLink
                to="/"
                className="nav-item nav-link header_nav_links"
                exact
              >
                {t("Main")}
              </NavLink>
              <NavLink
                to="/about-us"
                className="nav-item nav-link header_nav_links "
              >
                {t("AboutUs")}
              </NavLink>

              <NavLink
                to="/training-courses"
                className="nav-item nav-link header_nav_links "
              >
                {t("courses")}
              </NavLink>
              <NavLink
                to="/training-programs"
                className="nav-item nav-link header_nav_links "
              >
                {t("program")}
              </NavLink>
              <NavLink
                to="/trainers"
                className="nav-item nav-link header_nav_links "
              >
                {t("ProfessionalTrainers")}
              </NavLink>
              <NavLink
                to="/Exhibition"
                className="nav-item nav-link header_nav_links"
              >
                {t("Exhibition.Exhibition")}
              </NavLink>
              <NavLink
                to="/general-surveys"
                className="nav-item nav-link header_nav_links"
              >
                {t("sidebar.label.generalSurveys")}
              </NavLink>
              {/* <NavLink
              to="/advertisments"
              className="nav-item nav-link header_nav_links"
            >
              {t("Ads")}
            </NavLink> */}
              {/* {authContext.roles.includes("trainer") ||
              authContext.roles.includes("sponser") ||
              (authContext.roles.includes("accountant") &&
                JSON.parse(
                  localStorage.getItem("system_preferences")
                    ? localStorage.getItem("system_preferences")
                    : "{}"
                ).support_tickets_service) ? (
                <NavLink
                  to="/tickets"
                  className="nav-item nav-link header_nav_links"
                >
                  {t("sidebar.label.support_tickets")}
                </NavLink>
              ) : null} */}

              {/* <NavLink
                to="/terms-and-conditions"
                className="nav-item nav-link header_nav_links"
              >
                {t("TermsAndConditions")}
              </NavLink> */}

              <NavLink
                to="/contactUs"
                className="nav-item nav-link header_nav_links"
              >
                {t("ConnectUs")}
              </NavLink>

              {/* {chatAllowed && (
                <NavLink to="/livechat" className="nav-item nav-link header_nav_links">
                  {t("sidebar.label.live_chat")}
                </NavLink>
              )} */}
              {/* <NavLink to="/training-courses" className="nav-item nav-link header_nav_links ">
              {t("TrainingCourses")}
            </NavLink> */}
            </div>
          </div>
        )}
      </>
    );
  } else {
    authContext.setInAdminPanel(true);
    header = <AdminHeader roles={authContext.roles} />;
  }
  return (
    <>
      {(locationStr[1] !== "login" && locationStr[1] !== "register") ||
      localStorage.getItem("token")
        ? header
        : null}
      <Switch>
        <Route path="/" exact component={NewHome} />

        {authContext.roles.includes("admin") ? (
          <RouteInterceptor
            path="/admin"
            exact={false}
            component={Admin}
            show={true}
          />
        ) : null}

        {authContext.roles.includes("trainer") && (
          <RouteInterceptor
            path="/trainer"
            exact={false}
            component={Trainer}
            show={true}
          />
        )}

        {authContext.roles.includes("manager") && (
          <RouteInterceptor
            path="/manager"
            exact={false}
            component={Manager}
            show={true}
          />
        )}

        {authContext.roles.includes("trainer") && (
          <Route path="/overview" exact component={OverView} />
        )}
        {authContext.roles.includes("trainer") && (
          <Route path="/offered-courses" exact component={CoursesOffered} />
        )}
        {authContext.roles.includes("trainer") && (
          <Route path="/trainer-report" exact component={TrainerReport} />
        )}
        {authContext.roles.includes("partner") ? (
          <Route path="/my-sponsor-ship" exact component={MySponsorShip} />
        ) : null}
        {authContext.roles.includes("trainee") ? (
          <Route path="/trainee-report" exact component={TraineeReport} />
        ) : null}

        {!authContext.roles.includes("admin") &&
          !authContext.roles.includes("accountant") &&
          !authContext.roles.includes("trainer") && (
            <Route
              path="/purchasing-operations"
              exact
              component={purchasingOperations}
            />
          )}
        {!authContext.roles.includes("admin") &&
          !authContext.roles.includes("accountant") &&
          !authContext.roles.includes("trainer") && (
            <Route path="/all-refunds" exact component={AllRefunds} />
          )}
        {!authContext.roles.includes("admin") &&
          !authContext.roles.includes("accountant") &&
          !authContext.roles.includes("trainer") && (
            <Route path="/invoice-list" exact component={InvoiceList} />
          )}
        {!authContext.roles.includes("admin") &&
          !authContext.roles.includes("accountant") &&
          !authContext.roles.includes("trainer") && (
            <Route path="/financial-report" exact component={FinancialReport} />
          )}
        {!authContext.roles.includes("admin") &&
          !authContext.roles.includes("accountant") &&
          !authContext.roles.includes("trainer") && (
            <Route path="/wallet" exact component={Wallet} />
          )}
        {!authContext.roles.includes("admin") &&
          !authContext.roles.includes("accountant") &&
          !authContext.roles.includes("trainer") && (
            <Route path="/certificates" exact component={Certificates} />
          )}

        <RouteInterceptor
          path="/verify"
          exact={false}
          component={EmailVerificationRoutes}
          show={true}
        />

        {/* <RouteInterceptor path="/training-courses" component={HomeCourses} show={true} /> */}

        {is_logged_in ? (
          <>
            <Switch>
              {handleRoutesPaths.map((route, index) => (
                <Route
                  path={route.path}
                  exact={route.exact}
                  component={route.component}
                  key={index}
                />
              ))}
              <Route path={"/ticket/view/:id"} exact component={ViewTicket} />

              <Route path={"/tickets"} exact component={ListTickets} />

              <Route path={"/ticket/add"} exact component={AddTicket} />
              <Route path={"/ticket/rate/:id"} exact component={RateTicket} />
              {!authContext.roles.includes("accountant") ? (
                <Redirect to={"/private-profile"} />
              ) : null}
            </Switch>
            {authContext.roles.includes("accountant") ? (
              <AccountantProvider>
                <Switch>
                  <Route
                    exact={true}
                    path={"/accountant"}
                    component={Dashboard}
                  />
                  {/* ----------- */}
                  <Route
                    component={CreateCoupon}
                    path="/accountant/coupon/add"
                    exact={true}
                  />
                  <Route
                    component={EditCoupon}
                    path="/accountant/coupon/edit/:id"
                    exact={true}
                  />
                  <Route
                    component={() => <Coupons type="notGift" />}
                    path="/accountant/coupon"
                    exact={true}
                  />
                  <Route
                    component={() => <Coupons type="gift" />}
                    path="/accountant/gift-coupon"
                    exact={true}
                  />
                  <Route
                    component={() => <CouponDetails type="notGift" />}
                    path="/accountant/coupon/:id"
                    exact={true}
                  />
                  <Route
                    component={() => <CouponDetails type="gift" />}
                    path="/accountant/gift-coupon/:id"
                    exact={true}
                  />
                  {/* +++++++++++ */}
                  <Route
                    component={AddOffers}
                    path="/accountant/offer/add"
                    exact={true}
                  />
                  <Route
                    component={EditOffers}
                    path="/accountant/offer/edit/:id"
                    exact={true}
                  />
                  <Route
                    component={Offers}
                    path="/accountant/offer"
                    exact={true}
                  />
                  {/* +++++++++++ */}
                  <Route
                    component={AddTax}
                    path="/accountant/tax/add"
                    exact={true}
                  />
                  <Route
                    component={EditTax}
                    path="/accountant/tax/edit/:id"
                    exact={true}
                  />
                  <Route component={Tax} path="/accountant/tax" exact={true} />
                  {/* +++++++++++ */}
                  <Route
                    component={ReportFinancial}
                    path="/accountant/financial/report"
                    exact={true}
                  />
                  {/* +++++++++++ */}

                  <Route
                    component={() => <RefundList type="trainee" />}
                    path="/accountant/refund"
                    exact={true}
                  />
                  <Route
                    component={() => <RefundList type="exhibition-visitors" />}
                    path="/accountant/refund/exhibition-visitors"
                    exact={true}
                  />
                  <Route
                    component={() => <RefundList type="exhibition-booths" />}
                    path="/accountant/refund/exhibition-booths"
                    exact={true}
                  />
                  {/* +++++++++++ */}
                  <Route
                    component={() => <BankTransfers role="accountant" />}
                    path="/accountant/bank-transfers"
                    exact={true}
                  />
                  {/* +++++++++++ */}
                  <Route
                    component={Sponsor}
                    path="/accountant/sponsor"
                    exact={true}
                  />
                  <Route
                    component={AddNewTrainee}
                    path="/accountant/add/sponsor/:id"
                    exact={true}
                  />
                  <Route
                    component={AddSpon}
                    path="/accountant/add/sponsor"
                    exact={true}
                  />
                  {/* ----------- */}
                  <Route
                    component={ProgramPaymentListFinancial}
                    path="/accountant/program-payment-date"
                    exact={true}
                  />
                  <Route
                    component={() => (
                      <ProgramPaymentsReport role="accountant" />
                    )}
                    path="/accountant/program/:id/payments-report"
                    exact={true}
                  />
                  <Route
                    component={PercentageTrainersList}
                    path="/accountant/percentage-trainer/list"
                    exact={true}
                  />
                  <Route
                    component={PeymentRequestsCoursesPrograms}
                    path="/accountant/peyment-requests-courses"
                    exact={true}
                  />
                  <Route
                    component={() => (
                      <PeymentRequestsCoursesPrograms isProgram={true} />
                    )}
                    path="/accountant/peyment-requests-programs"
                    exact={true}
                  />
                  <Route
                    component={() => <Payments type="financial" />}
                    path="/accountant/financial-movement"
                    exact={true}
                  />
                  <Route
                    component={Fund}
                    path="/accountant/fund"
                    exact={true}
                  />
                  {/* ----------- */}
                  <Route
                    exact={true}
                    path={"/accountant/sponsors"}
                    component={Sponsors}
                  />
                  <Route
                    exact={true}
                    path={"/accountant/sponsor/addsponsor"}
                    component={AddSponsor}
                  />
                  <Route
                    exact={true}
                    path={"/accountant/sponsor/addtraineebyname"}
                    component={AddTraineeByName}
                  />
                  <Route
                    exact={true}
                    path={"/accountant/sponsor/addtraineebyemail"}
                    component={AddTraineeByEmail}
                  />
                  <Route
                    exact={true}
                    path={"/accountant/invoice"}
                    component={Invoice}
                  />
                  <Route
                    exact={true}
                    path={"/accountant/users-wallet"}
                    component={UserWallet}
                  />
                  <Route
                    exact={true}
                    path={"/accountant/users-wallet/details/:id"}
                    component={WalletDetails}
                  />
                  <Route
                    exact={true}
                    path={"/accountant/financial/total-income-report"}
                    component={TotalIncomeReport}
                  />

                  <Route
                    exact={true}
                    path={"/accountant/refund-setting/list"}
                    component={() => <RefundSettingList type="trainee" />}
                  />
                  <Route
                    exact={true}
                    path={"/accountant/refund-setting/list/exhibition-visitors"}
                    component={() => (
                      <RefundSettingList type="exhibition-visitors" />
                    )}
                  />
                  <Route
                    exact={true}
                    path={"/accountant/refund-setting/list/exhibition-booths"}
                    component={() => (
                      <RefundSettingList type="exhibition-booths" />
                    )}
                  />
                  <Route
                    exact={true}
                    path={"/accountant/refund-setting/add"}
                    component={() => <AddRefundSetting type="trainee" />}
                  />
                  <Route
                    exact={true}
                    path={"/accountant/refund-setting/exhibition-visitors/add"}
                    component={() => (
                      <AddRefundSetting type="exhibition-visitors" />
                    )}
                  />
                  <Route
                    exact={true}
                    path={"/accountant/refund-setting/exhibition-booths/add"}
                    component={() => (
                      <AddRefundSetting type="exhibition-booths" />
                    )}
                  />
                  <Route
                    exact={true}
                    path={"/accountant/refund-setting/edit/:id"}
                    component={() => <EditRefundSetting type="trainee" />}
                  />
                  <Route
                    exact={true}
                    path={
                      "/accountant/refund-setting/exhibition-visitors/edit/:id"
                    }
                    component={() => (
                      <EditRefundSetting type="exhibition-visitors" />
                    )}
                  />
                  <Route
                    exact={true}
                    path={
                      "/accountant/refund-setting/exhibition-booths/edit/:id"
                    }
                    component={() => (
                      <EditRefundSetting type="exhibition-booths" />
                    )}
                  />
                </Switch>
              </AccountantProvider>
            ) : null}
          </>
        ) : (
          <Switch>
            <Route path={"/register"} exact={true} component={MainAuth} />
            <Route
              path={"/login"}
              exact={true}
              component={
                localStorage.getItem("token") ? PrivateProfile : MainAuth
              }
            />
            <Route
              path={"/email-verification"}
              exact={true}
              component={MainAuth}
            />
            <Route path={"/trainers"} exact={true} component={Trainers} />
            <Route
              path={"/training-courses"}
              exact={true}
              component={HomeCourses}
            />
            <Route
              path={"/training-programs"}
              exact={true}
              component={() => <HomeCourses isProgram />}
            />
            <Route
              path={"/training-programs/:programID/details/course/:id/details"}
              exact={true}
              component={() => (
                <TrainingCoursesDetailsProvider backPath="program" />
              )}
            />
            <Route path={"/"} exact={true} component={NewHome} />
            <Route path={"/contactUs"} exact={true} component={ContactUs} />
            <Route path={"/about-us"} exact={true} component={AboutUs} />
            <Route path={"/contactUs"} exact={true} component={ContactUs} />
            <Route path={"/general-surveys"} exact={true} component={Surveys} />
            <Route path={"/guidelines"} exact={true} component={Guidelines} />
            <Route
              path={"/survey/:id/statistics"}
              exact={true}
              component={(props) => <SurveyQuestions {...props} isPublic />}
            />
            <Route path={"/Exhibition"} exact={true} component={Exhibition} />
            <Route
              path={"/general-surveys/:id"}
              exact={true}
              component={(props) => <SurveyView general={true} />}
            />
            <Route
              path="/survey/:id/exhibition/:targetExhibitionId"
              exact={true}
              component={(props) => <SurveyView general={true} />}
            />
            <Route
              path={"/survey/:id/course/:courseId"}
              exact={true}
              component={SurveyView}
            />
            <Route
              path={"/show-exhibition/unity-iframe/:id"}
              exact={true}
              component={UnityIframe}
            />
            <Route
              path={"/terms-and-conditions"}
              exact={true}
              component={TermsAndConditions}
            />
            <Route
              path={"/privacy-policy"}
              exact={true}
              component={PrivacyPolicy}
            />
            <Route path={"/home-search"} exact={true} component={SearchPage} />
            <Route
              path={"/training-courses/:id/details"}
              exact={true}
              component={() => (
                <TrainingCoursesDetailsProvider backPath="training-courses" />
              )}
            />
            <Route
              path={"/training-programs/:id/details"}
              exact={true}
              component={() => (
                <TrainingCoursesDetailsProvider
                  isProgram={true}
                  backPath="training-programs"
                />
              )}
            />
            <Route
              path={"/training-courses/:id/profile"}
              exact={true}
              children={<Redirect to={`/login`} />}
            />
            <Route
              path={"/show-exhibition/unity-iframe"}
              exact={true}
              children={<Redirect to={`/login`} />}
            />
            <Route
              path={"/forgot-password"}
              exact={true}
              component={ForgotPassword}
            />
            <Route
              path={"/trainers/trainer-details/:id"}
              exact={true}
              component={TrainerInfoDetails}
            />
            <Route
              path={"/send-requests"}
              exact={true}
              component={SendIpBlockedRequest}
            />
            <Route path={"/faq/faq-list"} exact={true} component={FAQUser} />
            <Route
              path={"/tickets/faq-list"}
              exact={true}
              component={FAQListEndUser}
            />
            {/* <Route path={"/faq/faq-list"} exact={true} component={FAQUser} /> OLD COMPONENT FaqList */}
            <Route path={"/all-program"} exact={true} component={AllPrograms} />
            {/*<Redirect to="/login"/>*/}
            <Route
              path={"/show-exhibition/unity-iframe/:id"}
              exact={true}
              component={UnityIframe}
            />
            <Route
              path={"/page-not-found"}
              exact={true}
              component={GenericNotFound}
            />
            <Redirect to="/page-not-found" />
          </Switch>
        )}

        <Redirect to="/page-not-found" />
        {/*{!localStorage.getItem("token") && <Redirect to="/login"/>}*/}
        {/*{localStorage.getItem("token") && <Redirect to="/page-not-found"/>}*/}
      </Switch>
    </>
  );
}

export default withRouter(withRoot(MainHeader));

import { ErrorMessage, Field, FieldArray } from "formik";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { SortableContainer, SortableElement, SortableHandle } from "react-sortable-hoc";
import { toast } from "react-toastify/dist";
import QuestionTypeEnum from "../../../Enums/QuestionTypeEnum";
import { MultipleDroppables } from "./MultipleDroppables/MultipleDroppables";

import { downloadFileFromLesson } from "../../../Services/api/courses/courseProvider";
import DownloadIcon from "../../../assets/icons/download3.svg";

import ReactCrop from "react-image-crop";
import styled from "styled-components";
import { ReactComponent as CloseIcon } from "../../../assets/icons/crose.svg";
import { ReactComponent as MemuIcon } from "../../../assets/icons/menu.svg";
import { ReactComponent as PhotoIcon } from "../../../assets/icons/photo.svg";
import { ReactComponent as PlusIcon } from "../../../assets/icons/plus-square.svg";
import { ReactComponent as YoutubeIcon } from "../../../assets/icons/youtube.svg";
import SkeletonCard from "../../Shared/Components/Spinner/SkeletonCard";
import classes from "./QuestionType.module.css";
import { launchacctivityUrl, launchQuestionUrl } from "../../../Services/api/exams/ExamsProvider";

export function JustPreview({ children }) {
  const { t } = useTranslation();
  return (
    <div className="tw-py-4">
      <div className="tw-relative tw-p-4 tw-border-[1px] tw-border-teal-400 tw-rounded">
        <div className="tw-bg-white tw-px-2 tw-text-teal-600 tw-absolute tw-left-8 -tw-top-4">{t("just_preview")}</div>
        {children}
      </div>
    </div>
  );
}

function DeleteChoose({ id, type = "options", onRemove, disabled = false }) {
  const { t } = useTranslation();
  return (
    <button
      type="button"
      disabled={disabled}
      onClick={async () => {
        try {
          onRemove();
        } catch (e) {
          toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{t("failed_deleting")}</span>);
        }
      }}
    >
      <CloseIcon className="tw-w-5 tw-h-5" />
    </button>
  );
}

function RadioQuestionOptions({ values, errors, setFieldValue, setValues, disabled }) {
  const { t } = useTranslation();
  useEffect(() => {
    if (!values.options.length) {
      setFieldValue(`options`, [
        { text: "", value: false },
        { text: "", value: false },
        { text: "", value: false },
      ]);
    }
  }, []);

  return (
    <FieldArray name={`options`}>
      {({ insert, remove, push }) => (
        <>
          <div className="tw-space-y-4">
            {values.options?.length &&
              values.options.map((option, index) => (
                <div key={index} className="tw-flex tw-items-center tw-space-s-4">
                  <Field
                    type="radio"
                    disabled={disabled}
                    name={`options.${index}.value`}
                    className="!tw-w-auto"
                    value={index}
                    checked={option.value}
                    onChange={(e) => {
                      setValues({
                        ...values,
                        options: values.options.map((option, optionIndex) => ({
                          text: option.text,
                          value: optionIndex == index,
                        })),
                      });
                    }}
                  />
                  <div className="tw-grow">
                    <Field
                      name={`options.${index}.text`}
                      disabled={disabled}
                      className="tw-block tw-w-full tw-border tw-border-gray-200 tw-rounded tw-p-2.5 tw-text-gray-500"
                      placeholder={t("add_title")}
                      type="text"
                      maxLength={5000}
                    />
                    <ErrorMessage
                      name={`options.${index}.text`}
                      component="div"
                      className="tw-text-xs tw-text-red-700 tw-h-4"
                    />
                  </div>
                  {disabled ? (
                    <></>
                  ) : (
                    <DeleteChoose id={option.id} onRemove={() => remove(index)} disabled={values.options.length <= 1} />
                  )}
                </div>
              ))}
          </div>
          {disabled ? (
            <></>
          ) : (
            <button type="button" onClick={() => push({ text: "", value: false })} className="tw-mt-3">
              <PlusIcon className="tw-h-6 tw-w-6 tw-stroke-[#046c77]" />
            </button>
          )}
        </>
      )}
    </FieldArray>
  );
}

function CheckboxQuestionOptions({ values, errors, setFieldValue, disabled }) {
  const { t } = useTranslation();
  useEffect(() => {
    if (!values.options.length) {
      setFieldValue(`options`, [
        { text: "", value: false },
        { text: "", value: false },
        { text: "", value: false },
      ]);
    }
  }, []);
  return (
    <FieldArray name={`options`}>
      {({ insert, remove, push }) => (
        <div>
          <div className="tw-space-y-4">
            {values.options?.length &&
              values.options.map((option, index) => (
                <div key={index} className="tw-flex tw-items-center tw-space-s-4">
                  <Field type="checkbox" disabled={disabled} name={`options.${index}.value`} className="!tw-w-auto" />
                  <div className="tw-grow">
                    <Field
                      name={`options.${index}.text`}
                      disabled={disabled}
                      className="tw-block tw-w-full tw-border tw-border-gray-200 tw-rounded tw-p-2.5 tw-text-gray-500"
                      placeholder={t("add_title")}
                      type="text"
                      maxLength={5000}
                    />
                    <ErrorMessage
                      name={`options.${index}.text`}
                      component="div"
                      className="tw-text-xs tw-text-red-700 tw-h-4"
                    />
                  </div>
                  {disabled ? (
                    <></>
                  ) : (
                    <DeleteChoose id={option.id} onRemove={() => remove(index)} disabled={values.options.length <= 1} />
                  )}
                </div>
              ))}
          </div>
          {disabled ? (
            <></>
          ) : (
            <button type="button" onClick={() => push({ text: "", value: false })} className="tw-mt-3">
              <PlusIcon className="tw-h-6 tw-w-6 tw-stroke-[#046c77]" />
            </button>
          )}
        </div>
      )}
    </FieldArray>
  );
}

function OrderingQuestionOptions({ values, errors, setFieldValue, disabled }) {
  const { t } = useTranslation();
  useEffect(() => {
    if (!values.options.length) {
      setFieldValue(`options`, [{ text: "" }, { text: "" }, { text: "" }]);
    } else {
      setFieldValue(
        `options`,
        values.options.sort((a, b) => a.value - b.value)
      );
    }
  }, []);
  return (
    <FieldArray name={`options`}>
      {({ insert, remove, push }) => (
        <div>
          <div className="tw-space-y-4">
            {values.options?.length &&
              values.options.map((option, index) => (
                <div key={index} className="tw-flex tw-items-center tw-space-s-4">
                  <div className="tw-grow">
                    <Field
                      name={`options.${index}.text`}
                      disabled={disabled}
                      className="tw-block tw-w-full tw-border tw-border-gray-200 tw-rounded tw-p-2.5 tw-text-gray-500"
                      placeholder={t("add_title")}
                      type="text"
                      maxLength={5000}
                    />
                    <ErrorMessage
                      name={`options.${index}.text`}
                      component="div"
                      className="tw-text-xs tw-text-red-700 tw-h-4"
                    />
                  </div>
                  {disabled ? (
                    <></>
                  ) : (
                    <DeleteChoose id={option.id} onRemove={() => remove(index)} disabled={values.options.length <= 1} />
                  )}
                </div>
              ))}
          </div>
          {disabled ? (
            <></>
          ) : (
            <button type="button" onClick={() => push({ text: "" })} className="tw-mt-3">
              <PlusIcon className="tw-h-6 tw-w-6 tw-stroke-[#046c77]" />
            </button>
          )}
        </div>
      )}
    </FieldArray>
  );
}

function DragAndDropQuestionOptions({ values, errors, setFieldValue, disabled }) {
  const { t } = useTranslation();

  useEffect(() => {
    if (!values.options.length) {
      setFieldValue(`options`, [
        { value: "", text: "" },
        { value: "", text: "" },
        { value: "", text: "" },
      ]);
    }
  }, []);

  return (
    <>
      <FieldArray name={`options`}>
        {({ insert, remove, push }) => (
          <div>
            <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 lg:tw-grid-cols-3 tw-gap-4">
              {values.options?.length &&
                values.options.map((option, index) => (
                  <div key={index} className="tw-relative tw-flex tw-space-y-2 tw-w-full tw-aspect-square">
                    <label className="tw-relative  tw-mb-20 tw-shrink-0 tw-flex tw-items-center tw-justify-center tw-rounded tw-w-full tw-bg-gray-100 tw-shadow-sm">
                      <input
                        type="file"
                        className="tw-hidden"
                        disabled={disabled}
                        onChange={(e) => setFieldValue(`options.${index}.value`, e.target.files[0])}
                        accept=".png,.jpg,.jpeg"
                        placeholder={t("survey.title")}
                      />
                      {option.value ? (
                        <img
                          src={option.value.name ? URL.createObjectURL(option.value) : option.image}
                          className="tw-w-full tw-max-h-full tw-shrink-0 tw-rounded tw-object-contain"
                        />
                      ) : (
                        <div className="tw-flex tw-flex-col tw-items-center tw-space-y-4 tw-p-4">
                          <PhotoIcon className="tw-h-8" />
                          <div className="tw-text-gray-400">{t("add_image")}</div>
                        </div>
                      )}
                      {disabled ? (
                        <></>
                      ) : (
                        <div className="tw-absolute tw-top-2 tw-left-2">
                          <DeleteChoose
                            id={option.id}
                            onRemove={() => remove(index)}
                            disabled={values.options.length <= 1}
                          />
                        </div>
                      )}
                    </label>
                    <div className="tw-absolute tw-bottom-0 tw-w-full tw-h-16">
                      <Field
                        name={`options.${index}.text`}
                        disabled={disabled}
                        className="tw-block tw-w-full tw-border tw-border-gray-200 tw-rounded tw-p-2.5 tw-text-gray-500"
                        placeholder={t("add_title")}
                        type="text"
                        maxLength={5000}
                      />
                      {errors.options && errors.options[index] ? (
                        <>
                          <div className="tw-text-xs tw-text-red-700 tw-h-4">
                            {typeof errors.options[index] == "string" ? (
                              <>{errors.options[index]}</>
                            ) : (
                              <>
                                <div>{errors.options[index]["value"]}</div>
                                <div>{errors.options[index]["text"]}</div>
                              </>
                            )}
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                ))}
            </div>
            {disabled ? (
              <></>
            ) : (
              <button type="button" onClick={() => push({ value: "" })} className="tw-h-6 tw-w-6 tw-mt-3">
                <PlusIcon className="tw-h-6 tw-w-6 tw-stroke-[#046c77]" />
              </button>
            )}
          </div>
        )}
      </FieldArray>
    </>
  );
}

export function HotSpotOptions({ values, errors, setFieldValue, disabled }) {
  const { t } = useTranslation();
  return (
    <div>
      {values.media ? (
        <div>
          <ReactCrop
            disabled={disabled}
            crop={{
              unit: "%",
              ...Object.fromEntries(new Map(values.options.map((item) => [item.text, item.value]))),
            }}
            onChange={(pixelSelection, percentageSelection) => {
              let options = [];
              Object.keys(percentageSelection).forEach((key) => {
                if (key != "unit") {
                  let option = {
                    text: key,
                    value: percentageSelection[key],
                  };
                  let optionId = values.options.find((i) => i.text == key)?.id;
                  if (option) option.id = optionId;
                  options.push(option);
                }
              });
              setFieldValue("options", options);
            }}
          >
            <img src={values.media?.id ? values.media?.path : URL.createObjectURL(values.media)} />
          </ReactCrop>

          <ErrorMessage name={`options`} component="div" className="tw-text-xs tw-text-red-700 tw-h-4" />
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}

function CheckboxQuestionView({ values, errors, setFieldValue, question, questionIndex, revision = false }) {
  const { t } = useTranslation();
  return (
    <div>
      <FieldArray name={`questions.${questionIndex}.answers`}>
        {({ insert, remove, push }) => (
          <>
            <div>
              {question.options.map((option, index) => (
                <div key={index}>
                  <label className="tw-flex tw-items-center tw-space-s-2">
                    <Field
                      id={`questions.${questionIndex}.answers.${index}.answer`}
                      type={"checkbox"}
                      value={option.id}
                      checked={values.answers.find((i) => i.answer == option.id)}
                      onChange={({ target: { checked, value } }) => {
                        const findInd = values.answers.findIndex((i) => i.answer == value);
                        if (findInd < 0) push({ answer: value, option_id: value });
                        else remove(findInd);
                      }}
                    />
                    <div
                      className={`tw-text-gray-500 tw-pb-2 ${
                        revision
                          ? values.answers.find((i) => i.option_id == option.id) && !option.value
                            ? "tw-text-red-500"
                            : option.value
                            ? "tw-text-green-500"
                            : ""
                          : ""
                      }`}
                    >
                      {option.text}
                    </div>
                  </label>
                </div>
              ))}
            </div>
            <ErrorMessage
              name={`questions.${questionIndex}.answers.0.answer`}
              component="div"
              className="tw-text-xs tw-text-red-700 tw-h-4"
            />
          </>
        )}
      </FieldArray>
    </div>
  );
}

function RadioQuestionView({ values, errors, setFieldValue, question, questionIndex, revision = false }) {
  const { t } = useTranslation();
  return (
    <div>
      {question.options.map((option, index) => (
        <div key={index}>
          <label className="tw-flex tw-items-center tw-space-s-2">
            <Field
              type={"radio"}
              id={`questions.${questionIndex}.answers.${index}.answer`}
              value={option.id}
              checked={values.answers[0]?.answer == option.id && option.id != null}
              onChange={({ target: { value } }) => {
                setFieldValue(`questions.${questionIndex}.answers.0`, {
                  option_id: value,
                  answer: value,
                });
              }}
            />
            <ErrorMessage
              name={`questions.${questionIndex}.answers.0.answer`}
              component="div"
              className="tw-text-xs tw-text-red-700 tw-h-4"
            />
            <div
              className={`tw-text-gray-500 tw-pb-2 ${
                revision
                  ? values.answers.find((i) => i.option_id == option.id) && !option.value
                    ? "tw-text-red-500"
                    : option.value
                    ? "tw-text-green-500"
                    : ""
                  : ""
              }`}
            >
              {option.text}
            </div>
          </label>
        </div>
      ))}
    </div>
  );
}

function StringQuestionView({ values, errors, setFieldValue, question, questionIndex }) {
  const { t } = useTranslation();
  return (
    <div>
      <Field
        type="text"
        name={`questions.${questionIndex}.answers.0.answer`}
        value={values.answers[0]?.answer ?? ""}
        className="tw-block tw-w-full tw-border tw-border-gray-200 tw-rounded tw-p-2.5 tw-text-gray-500"
        placeholder={t("add_text_here")}
        onChange={({ target: { value } }) => {
          setFieldValue(`questions.${questionIndex}.answers.0.answer`, value);
        }}
      />
    </div>
  );
}

function TextQuestionView({ values, errors, setFieldValue, question, questionIndex }) {
  const { t } = useTranslation();
  return (
    <div>
      <Field
        as="textarea"
        name={`questions.${questionIndex}.answers.0.answer`}
        value={values.answers[0]?.answer ?? ""}
        className="tw-block tw-w-full tw-border tw-border-gray-200 tw-rounded tw-p-2.5 tw-text-gray-500 tw-whitespace-pre-wrap tw-h-24"
        placeholder={t("add_text_here")}
        onChange={({ target: { value } }) => {
          setFieldValue(`questions.${questionIndex}.answers.0.answer`, value);
        }}
      />
    </div>
  );
}

function OrderingQuestionView({ values, errors, setFieldValue, question, questionIndex, revision = false }) {
  const { t } = useTranslation();
  const [isRevision, setIsRevision] = useState(revision);

  useEffect(() => {
    if (!values.answers.length) {
      let options = question.options;
      if (isRevision) {
        options = options.sort((a, b) => parseInt(a.value) - parseInt(b.value));
        setIsRevision(false);
      }
      setFieldValue(
        `questions.${questionIndex}.answers`,
        options.map((option, index) => ({
          option_id: option.id,
          answer: index,
        }))
      );
    } else {
    }
  });

  const reorderItems = (from, to, list) => {
    var unOrderedQuestions = [...list];
    var sourceItem = unOrderedQuestions.splice(from, 1)[0];
    unOrderedQuestions.splice(to, 0, sourceItem);
    const answers = unOrderedQuestions.map((option, index) => ({
      option_id: option.id,
      answer: index,
    }));
    return answers;
  };

  const SortableHandler = SortableHandle(({}) => <MemuIcon className="tw-h-4 tw-w-4 tw-cursor-move" />);

  const SortableItem = SortableElement(({ option, optionIndex }) => {
    return (
      <div
        className={`tw-relative tw-flex tw-items-center tw-w-full tw-space-s-3 tw-h-12 tw-px-4 tw-bg-white tw-border tw-border-gray-200 tw-text-gray-400 tw-rounded tw-cursor-move tw-mb-4`}
      >
        <div className="tw-h-4 tw-w-4">
          <SortableHandler />
        </div>
        <div>{option.text}</div>

        <div
          className={`${
            isRevision
              ? values.answers.find((ans) => ans.option_id == option.id)?.answer === option.value
                ? "tw-bg-green-500"
                : "tw-bg-red-500"
              : "tw-bg-teal-500"
          } tw-text-white tw-p-1 tw-absolute tw-end-3 tw-top-0 tw-h-8 tw-aspect-square tw-text-center`}
        >
          {optionIndex + 1}
        </div>
      </div>
    );
  });

  const SortableList = SortableContainer(({ options }) => {
    return (
      <div>
        {options.map((option, optionIndex) => (
          <SortableItem key={optionIndex} option={option} index={optionIndex} optionIndex={optionIndex} />
        ))}
      </div>
    );
  });

  function getSortedOptions(answers) {
    if (answers.length) {
      const options = question.options.map((option) => ({
        ...option,
        order: answers.find((ans) => ans.option_id == option.id)?.answer,
      }));

      return options.sort((a, b) => a.order - b.order);
    } else return question.options;
  }

  return (
    <div className="tw-space-y-3 tw-w-3/5">
      <SortableList
        onSortEnd={({ oldIndex, newIndex }) => {
          setFieldValue(
            `questions.${questionIndex}.answers`,
            reorderItems(oldIndex, newIndex, getSortedOptions(values.answers))
          );
        }}
        options={getSortedOptions(values.answers)}
        disableAutoscroll
        useDragHandle={true}
      />
    </div>
  );
}

function DragAndDropQuestionView({ values, errors, setFieldValue, question, questionIndex, revision = false }) {
  const { t } = useTranslation();
  const [items, setItems] = useState([]);
  useEffect(() => {
    if (question.values?.length) setItems(question.values);
    else
      setItems(
        question.options.map((option) => (option.value.name ? URL.createObjectURL(option.value) : option.image))
      );
  }, []);
  let temp = <div className="tw-border-green-500" />;
  let temp2 = <div className="tw-border-red-500" />;

  return (
    <MultipleDroppables
      options={question.options.map((option) => ({
        id: option.id,
        value: option.text,
      }))}
      items={items}
      answers={values.answers.map((item) => ({
        option_id: item.option_id,
        answer: item.answer,
        flagColor: revision
          ? item.answer === question.options.find((o) => item.option_id == o.id)?.value
            ? "tw-border-green-500"
            : "tw-border-red-500"
          : null,
      }))}
      setAnswers={(answers) => {
        setFieldValue(`questions.${questionIndex}.answers`, answers);
      }}
    />
  );
}

function ScormQuestionView({ values, errors, setFieldValue, question, questionIndex }) {
  const { t } = useTranslation();
  const [score, setScore] = useState(null);
  const [hasRaw, setHasRaw] = useState(false);
  // const [linkURL, setLinkURL] = useState("");

  function saveRes(e) {
    if (e.data["cmi_element"] == "cmi.core.score.raw" || e.data["cmi_element"] == "cmi.score.raw") {
      if (score != e.data["value"]) {
        setScore(e.data["value"]);
        setHasRaw(true);
      }
    }
    // else if (
    //   (e.data["cmi_element"] == "cmi.success_status" ||
    //     e.data["cmi_element"] == "cmi.core.lesson_status") &&
    //   !hasRaw
    // ) {
    //   setScore(e.data["value"] != "incomplete" ? 0 : 100);
    // }
  }

  useEffect(() => {
    if (question.media?.path) {
      window.addEventListener("message", saveRes);
      return () => window.removeEventListener("message", saveRes);
    }
  }, []);

  useEffect(() => {
    if (score != null)
      setFieldValue(`questions.${questionIndex}.answers.0.answer`, parseFloat(score) ? parseFloat(score) / 100 : 0);
  }, [score]);

  // useEffect(() => {
  //   const fetchImage = async () => {
  //     const response = await fetch(question.link, {
  //       method: "GET",
  //       headers: {
  //         Authorization: `Bearer ${localStorage.getItem("token") ?? localStorage.getItem("guest_token")}`,
  //       },
  //     });
  //     const blob = await response.blob();
  //     setLinkURL(URL.createObjectURL(blob));
  //   };

  //   if (question.link) fetchImage();
  // }, [question.link]);
  return (
    <>
      {question.media?.link ? (
        <iframe
          id={question.id}
          title={"Scorm"}
          src={`${process.env.REACT_APP_SERVER_PATH}scorm/player?path=${question.media.link}`}
          display="flex"
          height={"644px"}
          width="100%"
          allow="fullscreen"
        ></iframe>
      ) : (
        <button
          type="button"
          disabled={!question.link}
          className="tw-border-2 tw-border-gray-200 tw-bg-gray-50 tw-w-1/2 tw-aspect-square tw-flex tw-items-center tw-justify-center"
        >
          <YoutubeIcon className={`tw-w-16 tw-h-16 ${question.link ? "tw-stroke-teal-600" : "tw-stroke-gray-400"}`} />
        </button>
      )}
    </>
  );
}

function HTMLQuestionView({ values, errors, setFieldValue, question, questionIndex }) {
  const { t } = useTranslation();
  const iframeRef = useRef(null);
  const [linkURL, setLinkURL] = useState("");

  function saveRes(ev) {
    if (!process.env.REACT_APP_SERVER_PATH.startsWith(ev.origin)) {
      return;
    }
    if (ev.data === "true" || ev.data === "false") {
      let res = ev.data === "true" ? 1 : 0;
      setFieldValue(`questions.${questionIndex}.answers.0.answer`, res);
    }
  }
  useEffect(() => {
    if (question.media?.link) {
      window.addEventListener("message", saveRes);
      return () => window.removeEventListener("message", saveRes);
    }
  }, []);

  // useEffect(() => {
  //   const fetchImage = async () => {
  //     const response = await fetch(question.link, {
  //       method: "GET",
  //       headers: {
  //         Authorization: `Bearer ${localStorage.getItem("token") ?? localStorage.getItem("guest_token")}`,
  //       },
  //     });
  //     const blob = await response.blob();
  //     setLinkURL(URL.createObjectURL(blob));
  //   };

  //   if (question.link) fetchImage();
  // }, [question.link]);

  return (
    <>
      {question.media?.link ? (
        <iframe
          ref={iframeRef}
          id={"iframeScormQuestion" + question.id}
          src={question.media?.link}
          key={question.id}
          width={"100%"}
          height={"644px"}
        ></iframe>
      ) : (
        <button
          type="button"
          disabled={!question.media?.link}
          className="tw-border-2 tw-border-gray-200 tw-bg-gray-50 tw-w-1/2 tw-aspect-square tw-flex tw-items-center tw-justify-center"
        >
          <YoutubeIcon
            className={`tw-w-16 tw-h-16 ${
              question.media?.link ? "tw-stroke-teal-600" : "tw-stroke-gray-400"
            }`}
          />
        </button>
      )}
    </>
  );
}

function XApiQuestionView({ values, errors, setFieldValue, question, questionIndex, isActivity }) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [link, setLink] = useState(false);
  const [registration, setRegistration] = useState("00" + crypto.randomUUID().slice(2));
  // const [linkURL, setLinkURL] = useState("");

  useEffect(() => {
  const loadLink = async () => {
    if (question.media?.id) {
      setIsLoading(true);
      const res = isActivity
        ? await launchacctivityUrl(question.id, registration)
        : await launchQuestionUrl(question.media.id, registration);
      setLink(res.data.path);
      setIsLoading(false);
      setFieldValue(`questions.${questionIndex}.answers.0.answer`, registration);
    }
  }
  loadLink()
  }, [isActivity, question.id, question.media.id, questionIndex, registration, setFieldValue]);

  // useEffect(() => {
  //   const fetchImage = async () => {
  //     const response = await fetch(question.link, {
  //       method: "GET",
  //       headers: {
  //         Authorization: `Bearer ${localStorage.getItem("token") ?? localStorage.getItem("guest_token")}`,
  //       },
  //     });
  //     const blob = await response.blob();
  //     setLinkURL(URL.createObjectURL(blob));
  //   };

  //   if (question.link) fetchImage();
  // }, [question.link]);

  return (
    <>
      {question.media?.link ? (
        <>
          {isLoading && !link ? (
            <div className="tw-mx-auto">
              <div style={{ height: "644px" }}>
                <SkeletonCard />
              </div>
            </div>
          ) : (
            <iframe
              id={question.id}
              title={"XAPI"}
              src={link}
              display="flex"
              height={"644px"}
              width="100%"
            ></iframe>
          )}
        </>
      ) : (
        <button
          type="button"
          disabled={!question.link}
          className="tw-border-2 tw-border-gray-200 tw-bg-gray-50 tw-w-1/2 tw-aspect-square tw-flex tw-items-center tw-justify-center"
        >
          <YoutubeIcon className={`tw-w-16 tw-h-16 ${question.link ? "tw-stroke-teal-600" : "tw-stroke-gray-400"}`} />
        </button>
      )}
    </>
  );
}

function H5pQuestionView({ values, errors, setFieldValue, question, questionIndex, isActivity }) {
  const { t } = useTranslation();
  const iframeRef = useRef(null);
  // const [linkURL, setLinkURL] = useState("");

  function saveRes(event) {
    if (!process.env.REACT_APP_SERVER_PATH.startsWith(event.origin)) {
      return;
    }
    if (event.data.statement?.result) {
      setFieldValue(`questions.${questionIndex}.answers.0.answer`, event.data.statement.result.score.scaled);
    }
  }

  useEffect(() => {
    if (question.media?.path) {
      window.addEventListener("message", saveRes);
      return () => window.removeEventListener("message", saveRes);
    }
  }, []);

  // useEffect(() => {
  //   const fetchImage = async () => {
  //     const response = await fetch(question.link, {
  //       method: "GET",
  //       headers: {
  //         Authorization: `Bearer ${localStorage.getItem("token") ?? localStorage.getItem("guest_token")}`,
  //       },
  //     });
  //     const blob = await response.blob();
  //     setLinkURL(URL.createObjectURL(blob));
  //   };

  //   if (question.link) fetchImage();
  // }, [question.link]);

  return (
    <>
      {question.media?.link ? (
        <iframe
          ref={iframeRef}
          id={"iframeH5PQuestion" + question.id}
          src={`${process.env.REACT_APP_SERVER_PATH}h5p/player?path=${question.media?.link}`}
          key={question.id}
          width={"100%"}
          height={"644px"}
          allow="fullscreen"
        ></iframe>
      ) : (
        <button
          type="button"
          disabled={!question.link}
          className="tw-border-2 tw-border-gray-200 tw-bg-gray-50 tw-w-1/2 tw-aspect-square tw-flex tw-items-center tw-justify-center"
        >
          <YoutubeIcon className={`tw-w-16 tw-h-16 ${question.link ? "tw-stroke-teal-600" : "tw-stroke-gray-400"}`} />
        </button>
      )}
    </>
  );
}

// تعريف ClickableSVG خارج المكون
const ClickableSVG = styled.svg`
  width: 100%;
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
`;

function HotSpotQuestionView({
  values,
  errors,
  setFieldValue,
  question,
  questionIndex,
  revision = false,
  isActivity,
}) {
  const img = useRef(null);
  const [screenX, setScreenX] = useState(0);
  const [screenY, setScreenY] = useState(0);
  const [screenWidth, setScreenWidth] = useState(0);
  const [screenHeight, setScreenHeight] = useState(0);
  const [isLoaded, setIsLoaded] = useState(false);
  const [display, setDisplay] = useState(values?.answers?.length && revision ? "block" : "none");
  // const [linkURL, setLinkURL] = useState("");

  function imageClick(e) {
    var x = e.nativeEvent.offsetX;
    var y = e.nativeEvent.offsetY;
    setScreenX(x);
    setScreenY(y);
    setDisplay("block");
    setFieldValue(`questions.${questionIndex}.answers`, [
      { answer: (x / screenWidth) * 100 },
      { answer: (y / screenHeight) * 100 },
    ]);
  }

  useEffect(() => {
    if (!img.current) return;
    const resizeObserver = new ResizeObserver(() => {
      if (img.current) {
        setScreenWidth(img.current.getBoundingClientRect().width);
        setScreenHeight(img.current.getBoundingClientRect().height);
      }
    });
    resizeObserver.observe(img.current);
    return () => resizeObserver.disconnect(); // clean up
  }, []);

  useEffect(() => {
    if (values.answers?.length && screenWidth && screenHeight) {
      let xp = parseFloat(values.answers[0].answer);
      let yp = parseFloat(values.answers[1].answer);
      setScreenX((xp * screenWidth) / 100);
      setScreenY((yp * screenHeight) / 100);
    }
  }, [screenWidth, screenHeight, values.answers]);

  // useEffect(() => {
  //   const fetchImage = async () => {
  //     const response = await fetch(question.link, {
  //       method: "GET",
  //       headers: {
  //         Authorization: `Bearer ${localStorage.getItem("token") ?? localStorage.getItem("guest_token")}`,
  //       },
  //     });
  //     const blob = await response.blob();
  //     setLinkURL(URL.createObjectURL(blob));
  //   };

  //   if (question.link) fetchImage();
  // }, [question.link]);

  return (
    <>
      <div
        ref={img}
        style={{
          maxWidth: "100%",
          maxHeight: "640px",
          aspectRatio: question?.media ? `${question?.media?.size[0]}/${question?.media?.size[1]}` : "",
        }}
      >
        <ClickableSVG 
          onClick={imageClick}
          style={{
            backgroundImage: `url(${question?.media?.link})`,
          }}>
          <circle
            cx={screenX}
            cy={screenY}
            r="20"
            stroke="#126e77"
            strokeWidth="4"
            fill="none"
            style={{
              zIndex: "1",
              display: display,
            }}
          />
        </ClickableSVG>
      </div>
    </>
  );
}

function TextView({ question }) {
  return (
    <div>
      <div dangerouslySetInnerHTML={{ __html: question.content }} />
    </div>
  );
}
function AudioView({ question }) {
  const { t } = useTranslation();
  const [linkURL, setLinkURL] = useState("");

  useEffect(() => {
    const fetchImage = async () => {
      const response = await fetch(question.link, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token") ?? localStorage.getItem("guest_token")}`,
        },
      });
      const blob = await response.blob();
      setLinkURL(URL.createObjectURL(blob));
    };

    if (question.link) fetchImage();
  }, [question.link]);

  return (
    <div>
      <div className={classes["exhibition-details__audios-item"]}>
        <div className={classes["exhibition-details__audios-item-title"]}>
          {t("trainer.course.lesson.audio.previewtitle")}
        </div>
        {linkURL && (
          <audio controls>
            <source src={linkURL} />
          </audio>
        )}
      </div>
      <div>
        <div
          dangerouslySetInnerHTML={{ __html: question.content }}
          style={{
            padding: 20,
            color: "#6d6d6d",
            whiteSpace: "pre-line",
          }}
        />
      </div>
    </div>
  );
}
function FileView({ question }) {
  const { t } = useTranslation();
  const [linkURL, setLinkURL] = useState("");

  const handleDownload = () => {
    const type = "contnet";
    downloadFileFromLesson(question?.title, question?.extension, question?.id, type);
  };

  const [width, setWidth] = useState(0);

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [setWidth]);

  useEffect(() => {
    const fetchImage = async () => {
      const response = await fetch(question.link, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token") ?? localStorage.getItem("guest_token")}`,
        },
      });
      const blob = await response.blob();
      setLinkURL(URL.createObjectURL(blob));
    };

    if (question.link) fetchImage();
  }, [question.link]);

  return (
    <div>
      <div>
        <div
          dangerouslySetInnerHTML={{ __html: question.content }}
          style={{
            padding: 20,
            color: "#6d6d6d",
            whiteSpace: "pre-line",
          }}
        />
      </div>
      {width <= 500 ? (
        <div
          className={classes["downloadFile"]}
          style={{
            marginBottom: "10px",
          }}
        >
          <button className={classes["file-download"]} type="button" onClick={() => handleDownload()}>
            <img
              src={DownloadIcon}
              alt="Download Files"
              className={classes["downloadicon"]}
              style={{
                background: "#29b3b81f",
                borderRadius: "10px",
                padding: "15px 15px",
                marginBottom: "10px",
              }}
            />
            <span style={{ fontWeight: "bold" }}>{t("download_files")}</span>
          </button>
        </div>
      ) : (
        <iframe src={linkURL} width="975" height="612" frameborder="0" allowfullscreen title="PDF Preview"></iframe>
      )}
    </div>
  );
}
function VideoView({ question }) {
  const getYouTubeSrc = (videoUrl) => {
    // Function to extract the video ID from the YouTube URL
    const getYouTubeId = (url) => {
      const match = url.match(
        /^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/
      );
      return match && match[1];
    };

    // Extract video ID from the provided YouTube URL
    const videoId = getYouTubeId(videoUrl);

    // If a valid video ID is found, generate the src link
    return videoId ? `https://www.youtube.com/embed/${videoId}` : "Invalid YouTube URL";
  };
  const iframeSrc = getYouTubeSrc(question.link);
  return (
    <div>
      <div>
        <div
          dangerouslySetInnerHTML={{ __html: question.content }}
          style={{
            padding: 20,
            color: "#6d6d6d",
            whiteSpace: "pre-line",
          }}
        />
      </div>
      <iframe
        src={iframeSrc}
        width="975"
        height="612"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen
        title={question.title}
      ></iframe>
    </div>
  );
}

function FilesView({ question }) {
  const { t } = useTranslation();
  const handleDownload = () => {
    const type = "contnet";
    downloadFileFromLesson(question?.title, question?.extension, question?.id, type);
  };
  return (
    <div>
      {/* <div style={{ paddingBottom: 15 }}>
        <div
          dangerouslySetInnerHTML={{ __html: question.content }}
          className={classes["course-slider-item-content-inner-body"]}
        />
      </div> */}
      <div className={classes["downloadFile"]}>
        <button className={classes["file-download"]} type="button" onClick={() => handleDownload()}>
          <img src={DownloadIcon} alt="Download Files" className={classes["downloadicon"]} />
          <span>{t("download_files")}</span>
        </button>
      </div>
    </div>
  );
}

const QuestionTypes = [
  {
    type: QuestionTypeEnum.checkbox,
    formComponent: CheckboxQuestionOptions,
    viewComponent: CheckboxQuestionView,
  },
  {
    type: QuestionTypeEnum.radio_button,
    formComponent: RadioQuestionOptions,
    viewComponent: RadioQuestionView,
  },
  {
    type: QuestionTypeEnum.ordering,
    formComponent: OrderingQuestionOptions,
    viewComponent: OrderingQuestionView,
  },
  {
    type: QuestionTypeEnum.drag_and_drop,
    formComponent: DragAndDropQuestionOptions,
    viewComponent: DragAndDropQuestionView,
  },
  {
    type: QuestionTypeEnum.text,
    formComponent: (props) => <></>,
    viewComponent: TextQuestionView,
  },
  {
    type: QuestionTypeEnum.essay_question,
    formComponent: (props) => <></>,
    viewComponent: StringQuestionView,
  },
  {
    type: QuestionTypeEnum.hotspot,
    formComponent: HotSpotOptions,
    viewComponent: HotSpotQuestionView,
  },
  {
    type: QuestionTypeEnum.h5p,
    formComponent: (props) => <></>,
    viewComponent: H5pQuestionView,
  },
  {
    type: QuestionTypeEnum.html,
    formComponent: (props) => <></>,
    viewComponent: HTMLQuestionView,
  },
  {
    type: QuestionTypeEnum.scorm,
    formComponent: (props) => <></>,
    viewComponent: ScormQuestionView,
  },
  {
    type: QuestionTypeEnum.xapi,
    formComponent: (props) => <></>,
    viewComponent: XApiQuestionView,
  },
  {
    type: QuestionTypeEnum.text_1,
    formComponent: (props) => <></>,
    viewComponent: TextView,
  },
  {
    type: QuestionTypeEnum.video,
    formComponent: (props) => <></>,
    viewComponent: VideoView,
  },
  {
    type: QuestionTypeEnum.pdf,
    formComponent: (props) => <></>,
    viewComponent: FileView,
  },
  {
    type: QuestionTypeEnum.audio,
    formComponent: (props) => <></>,
    viewComponent: AudioView,
  },
  {
    type: QuestionTypeEnum.file,
    formComponent: (props) => <></>,
    viewComponent: FilesView,
  },
];

export default QuestionTypes;

import { Formik } from "formik";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { NavLink } from "react-router-dom/cjs/react-router-dom";
import { toast } from "react-toastify";
import { IconsTooltip } from "../../../../Components";
import {
  exportExamData,
  getExamData,
} from "../../../../Services/api/exams/ExamsProvider";
import { ReactComponent as CloneIcon } from "../../../../assets/icons/clone.svg";
import { ReactComponent as EditIcon } from "../../../../assets/icons/edit.svg";
import HomeIcon from "../../../../assets/icons/home.svg";
import { ReactComponent as ParticipantsIcon } from "../../../../assets/icons/participants.svg";
import PdfIcon from "../../../../assets/icons/pdf.svg";
import { ReactComponent as ReportIcon } from "../../../../assets/icons/report.svg";
import XlsIcon from "../../../../assets/icons/xls.svg";
import Breadcrumb from "../../../Shared/Components/Breadcrumb/Breadcrumb";
import SkeletonCardOverlay from "../../../Shared/Components/Spinner/SkeletonCardOverlay";
import QuestionTypes, { JustPreview } from "../QuestionTypes";
import { getCourseDetails } from "../../../../Services/api/courses/courseProvider";

export default function AssessmentPreview({ quizable, role }) {
  const [isLoading, setIsLoading] = useState(true);
  const [quiz, setQuiz] = useState({});
  const [isLoaded, setIsLoaded] = useState(false);
  const { courseId, quizableId, quizId } = useParams();
  const [isExportLoading, setIsExportLoading] = useState(true);
  const { t, i18n } = useTranslation();
  const [targetCourse, setTargetCourse] = useState({});
  useEffect(() => {
    setIsLoading(true);
    fetchData();
  }, []);

  function fetchData() {
    getExamData(quizId)
      .then((res) => {
        setQuiz(res.data.quiz);
        setIsLoading(false);
      })
      .catch((err) => {});
  }

  const exportHandler = async (type) => {
    setIsExportLoading(true);
    try {
      await exportExamData(type, quizId);
      setIsExportLoading(false);
    } catch (e) {
      setIsExportLoading(false);
      toast.error(
        <span style={{ fontSize: 13, fontWeight: "bold" }}>
          {t("failed_fetching")}
        </span>
      );
    }
  };

  const breadcrumbList = [
    {
      id: "home",
      page: <img src={HomeIcon} alt="" />,
      pagePath: "/",
    },
    // {
    //   id: t("sidebar.label.courses"),
    //   page: t("sidebar.label.courses"),
    //   pagePath: `/${role}/courses`,
    // },
    {
      id: t("Courseoutline"),
      page: t("Courseoutline"),
      pagePath: `/${role}${
        targetCourse?.program_id ? `/program/${targetCourse.program_id}` : ``
      }/course/${courseId}`,
    },
    {
      id: t("trainer.quiz.exams"),
      page: t("trainer.quiz.exams"),
      pagePath: `/${role}/course/${courseId}/${quizable}/${quizableId}/assessments`,
    },
    {
      id: 0,
      page: quiz?.title ?? "",
      active: true,
    },
  ];
  const getCourseDetailsAPI = () => {
    getCourseDetails(courseId)
      .then((res) => {
        if (res.status === 200 && res.data.status) {
          setTargetCourse(res.data.course);
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    if (courseId) {
      getCourseDetailsAPI();
    }
  }, [courseId]);

  return (
    <>
      <div className="container-fluid" id={"content-section"}>
        <div className="row">
          <div className="col-12 sm:tw-px-2 tw-py-8">
            {/*TODO*/}
            <Breadcrumb list={breadcrumbList} />
          </div>
        </div>
        {isLoading ? (
          <SkeletonCardOverlay skeletonWidth="100" />
        ) : (
          <>
            <div className="tw-space-y-4">
              <div className="tw-bg-white tw-shadow tw-rounded tw-px-8 tw-py-4 tw-space-y-3">
                <div className="tw-flex tw-items-center tw-justify-between">
                  <div className="tw-font-bold tw-text-xl tw-text-gray-500">
                    {quiz?.title}
                  </div>
                  <div className="tw-flex tw-items-center tw-space-s-2">
                    {/* <button
                      type="button"
                      onClick={() => cloneSurvayModal.current?.showModal()}
                    >
                      <IconsTooltip
                        title={t("survey.addClone")}
                        content={
                          <CloneIcon className="tw-h-7 tw-aspect-square" />
                        }
                      />
                    </button> */}
                    <NavLink
                      to={`/${role}/course/${courseId}/${quizable}/${quizableId}/assessment/${quizId}/participants`}
                    >
                      <IconsTooltip
                        title={t("participants")}
                        content={
                          <ParticipantsIcon className="tw-h-7 tw-aspect-square" />
                        }
                      />
                    </NavLink>
                    {role !== "partner" ? (
                      <NavLink
                        to={`/${role}/course/${courseId}/${quizable}/${quizableId}/assessment/edit/${quizId}`}
                      >
                        <IconsTooltip
                          title={t("survey.editButton")}
                          content={
                            <EditIcon className="tw-h-5 tw-aspect-square" />
                          }
                        />
                      </NavLink>
                    ) : null}
                    <NavLink
                      to={`/${role}/course/${courseId}/${quizable}/${quizableId}/assessment/${quizId}/statistics`}
                    >
                      <IconsTooltip
                        title={t("sidebar.report.title")}
                        content={
                          <ReportIcon className="w-h-8 tw-w-5 tw-aspect-square tw-stroke-[#9a9a9a]" />
                        }
                      />
                    </NavLink>
                    {role !== "partner" && (
                      <>
                        <button
                          type="button"
                          onClick={() => exportHandler("pdf")}
                          className="tw-flex tw-items-center tw-justify-center tw-h-10 tw-w-10 tw-rounded tw-bg-[#b91414] tw-border-none"
                        >
                          <img src={PdfIcon} alt="" />
                        </button>
                        <button
                          type="button"
                          onClick={() => exportHandler("xlsx")}
                          className="tw-flex tw-items-center tw-justify-center tw-h-10 tw-w-10 tw-rounded tw-bg-[#138d5f] tw-border-none"
                        >
                          <img src={XlsIcon} alt="" />
                        </button>
                      </>
                    )}
                  </div>
                </div>
              </div>
              <AssessmentQuestionPreview questions={quiz.questions} />
            </div>
          </>
        )}
      </div>
    </>
  );
}

export function AssessmentQuestionPreview({
  questions,
  isActivity,
  isContent,
}) {
  const { t, i18n } = useTranslation();
  return (
    <Formik
      initialValues={{
        questions: questions.map((question) => ({
          id: question.id,
          answers: [],
        })),
      }}
      onSubmit={async (values) => {}}
      validate={(values) => {
        const errors = {};
        return errors;
      }}
      validateOnChange={false}
    >
      {({ values, errors, handleSubmit, setFieldValue, setValues }) => (
        <form onSubmit={handleSubmit}>
          <div className="tw-space-y-4">
            {questions.map((question, index) => {
              const questionType = QuestionTypes.find(
                (type) => type.type == question?.type_id
              );
              return (
                <div className="tw-bg-white tw-shadow tw-rounded tw-px-8 tw-py-4 tw-space-y-4" key={index}>
                  <div className="tw-flex tw-items-center tw-justify-between tw-space-s-6 tw-w-full">
                    <div className="tw-flex tw-flex-wrap tw-items-center tw-grow tw-overflow-hidden tw-space-s-2 tw-text-xl tw-font-bold tw-text-teal-600">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: question.title,
                        }}
                      />
                      <div className="tw-whitespace-nowrap">
                        {i18n.language === "ar"
                          ? `(${question.type.name_ar})`
                          : `(${question.type.name_en})`}
                      </div>
                    </div>
                    <div className="tw-flex tw-items-center tw-text-gray-400">
                      <div className="tw-font-bold">{index + 1}</div>
                      <div>/</div>
                      <div>{questions.length}</div>
                    </div>
                  </div>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: question.text,
                    }}
                  />
                  <div>
                    {isActivity || isContent ? (
                      <JustPreview>
                        <div className="tw-overflow-x-auto inner-scrollbar">
                          <questionType.viewComponent
                            question={question}
                            questionIndex={index}
                            values={values.questions[index]}
                            errors={errors}
                            setFieldValue={setFieldValue}
                            setValues={setValues}
                            revision
                            isActivity={isActivity}
                          />
                        </div>
                      </JustPreview>
                    ) : (
                      <div className="tw-overflow-x-auto inner-scrollbar">
                        <questionType.viewComponent
                          question={question}
                          questionIndex={index}
                          values={values.questions[index]}
                          errors={errors}
                          setFieldValue={setFieldValue}
                          setValues={setValues}
                          revision
                          isActivity={isActivity}
                        />
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </form>
      )}
    </Formik>
  );
}

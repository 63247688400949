import { useEffect, useState } from "react";
import { ReactComponent as YoutubeIcon } from "../../../../../assets/icons/youtube.svg";
import SkeletonCard from "../../../../Shared/Components/Spinner/SkeletonCard";
import { launchacctivityUrl } from "../../../../../Services/api/exams/ExamsProvider";
import { toast } from "react-toastify";

export default function XApiQuestionView({
  question,
  setFieldValue,
  isActivity,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [link, setLink] = useState(false);
  // const [linkURL, setLinkURL] = useState("");
  const [registration, setRegistration] = useState(
    "00" + crypto.randomUUID().slice(2)
  );

  useEffect(() => {
    const launch = () => {
      if (question.media?.id) {
        setIsLoading(true);
        launchacctivityUrl(question.id, registration, true)
          .then((res) => {
            setFieldValue("answer", registration);
            setLink(res.data.path);
            setIsLoading(false);
          })
          .catch((err) => {
            toast.error(
              <span style={{ fontSize: 13, fontWeight: "bold" }}>
                {err.response.data?.msg ?? "Your answer to registration failed"}
              </span>
            );
          });
      }
    };
    launch();
  }, []);

  // useEffect(() => {
  //   const fetchImage = async () => {
  //     const response = await fetch(question.link, {
  //       method: "GET",
  //       headers: {
  //         Authorization: `Bearer ${localStorage.getItem("token") ?? localStorage.getItem("guest_token")}`,
  //       },
  //     });
  //     const blob = await response.blob();
  //     setLinkURL(URL.createObjectURL(blob));
  //   };

  //   if (question.link) fetchImage();
  // }, [question.link]);

  return (
    <>
      {question.link ? (
        <>
          {isLoading && !link ? (
            <div className="tw-mx-auto">
              <div style={{ height: "644px" }}>
                <SkeletonCard />
              </div>
            </div>
          ) : (
            <iframe
              id={question.id}
              title={"XAPI"}
              src={link}
              display="flex"
              height={"644px"}
              width="100%"
            ></iframe>
          )}
        </>
      ) : (
        <button
          type="button"
          disabled={!question.link}
          className="tw-border-2 tw-border-gray-200 tw-bg-gray-50 tw-w-1/2 tw-aspect-square tw-flex tw-items-center tw-justify-center"
        >
          <YoutubeIcon
            className={`tw-w-16 tw-h-16 ${
              question.link ? "tw-stroke-teal-600" : "tw-stroke-gray-400"
            }`}
          />
        </button>
      )}
    </>
  );
}

import { useEffect, useRef, useState } from "react";
import { ReactComponent as YoutubeIcon } from "../../../../../assets/icons/youtube.svg";

export default function HTMLQuestionView({ question, setFieldValue }) {
  const iframeRef = useRef(null);
  const [linkURL, setLinkURL] = useState("");

  function saveRes(ev) {
    if (!process.env.REACT_APP_SERVER_PATH.startsWith(ev.origin)) {
      return;
    }
    if (ev.data === "true" || ev.data === "false") {
      let res = ev.data === "true" ? 1 : 0;
      setFieldValue("answer", res);
    }
  }
  useEffect(() => {
    if (question.media?.link) {
      window.addEventListener("message", saveRes);
      return () => window.removeEventListener("message", saveRes);
    }
  }, []);

  // useEffect(() => {
  //   const fetchImage = async () => {
  //     const response = await fetch(question.link, {
  //       method: "GET",
  //       headers: {
  //         Authorization: `Bearer ${localStorage.getItem("token") ?? localStorage.getItem("guest_token")}`,
  //       },
  //     });
  //     const blob = await response.blob();
  //     setLinkURL(URL.createObjectURL(blob));
  //   };

  //   if (question.link) fetchImage();
  // }, [question.link]);

  return (
    <>
      {question.media?.link ? (
        <iframe
          ref={iframeRef}
          id={"iframeScormQuestion" + question.id}
          src={question.media?.link}
          key={question.id}
          width={"100%"}
          height={"644px"}
        ></iframe>
      ) : (
        <button
          type="button"
          disabled={!question.media?.link}
          className="tw-border-2 tw-border-gray-200 tw-bg-gray-50 tw-w-1/2 tw-aspect-square tw-flex tw-items-center tw-justify-center"
        >
          <YoutubeIcon
            className={`tw-w-16 tw-h-16 ${
              question.media?.link ? "tw-stroke-teal-600" : "tw-stroke-gray-400"
            }`}
          />
        </button>
      )}
    </>
  );
}

import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import SunEditor from "suneditor-react";
import HomeIcon from "../../../../assets/icons/home.svg";
import { Lang } from "../../../../utils";
import Breadcrumb from "../../../Shared/Components/Breadcrumb/Breadcrumb";
import Input from "../../../Shared/Components/Input/Input";
import MainBox from "../../../Shared/Components/MainBox/MainBox";
import MainBoxHead from "../../../Shared/Components/MainBoxHead/MainBoxHead";
import ManageSpecialtiesAction from "./ManageSpecialtiesAction";
import classes from "./manageSpecialties.module.css";
import InputFile from "../../../Shared/Components/InputFile/InputFile";

const ManageSpecialties = ({ type, submitHandler, specialtyDetails }) => {
  const { t, i18n } = useTranslation();
  const history = useHistory();

  const breadcrumbList = [
    {
      id: "home",
      page: <img src={HomeIcon} alt="" />,
      pagePath: "/",
    },
    {
      id: "specialties",
      page: t("specialties"),
      pagePath: "/Admin/category",
    },
    {
      id: "create_exhibition",
      page:
        type === "create"
          ? t("specialties_manage.add_new_specialties")
          : i18n.language == Lang.AR
          ? specialtyDetails?.name_ar
          : specialtyDetails?.name_en,
      active: true,
    },
  ];

  return (
    <div className={classes["manage-specialties"]}>
      <div className="container-fluid">
        <div className={classes["manage-specialties__container"]}>
          <div className="row">
            <div className="col-12">
              <Breadcrumb list={breadcrumbList} />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <MainBox>
                <Formik
                  initialValues={{
                    ...(type === "edit" && { _method: "put" }),
                    name_ar: specialtyDetails?.name_ar || "",
                    name_en: specialtyDetails?.name_en || "",
                    description_ar: specialtyDetails?.description_ar || "",
                    description_en: specialtyDetails?.description_en || "",
                    image: specialtyDetails?.image
                      ? {
                          name: specialtyDetails?.image,
                          preview: specialtyDetails?.image,
                        }
                      : "",
                  }}
                  onSubmit={async (values, { setSubmitting, setErrors }) => {
                    let errorObj = {};
                    try {
                      // console.log(values.image);
                      let data = { ...values };
                      if (values.image?.file) {
                        data.image = values.image?.file;
                      } else if (type === "edit" && !values.image) {
                        data.delete_image = 1;
                      }
                      const formData = new FormData();
                      for (let key in data) {
                        if (!data[key]) continue;
                        if (Array.isArray(data[key])) {
                          data[key].forEach((item) => {
                            formData.append(`${key}[]`, item);
                          });
                        } else {
                          formData.append(key, data[key]);
                        }
                      }
                      await submitHandler(formData);
                      setSubmitting(false);
                    } catch (err) {
                      if (
                        err.response.data.errors &&
                        err.response.data.errors.name_en
                      ) {
                        errorObj.name_en = err.response.data.errors.name_en;
                      }
                      if (
                        err.response.data.errors &&
                        err.response.data.errors.name_ar
                      ) {
                        errorObj.name_ar = err.response.data.errors.name_ar;
                      }
                      if (Object.keys(errorObj).length !== 0) {
                        setErrors(errorObj);
                      }
                      toast.error(
                        <>
                          <span
                            style={{
                              fontSize: 13,
                              fontWeight: "bold",
                              display: "block",
                              paddingBottom: "10px",
                            }}
                          >
                            {err.response.data.msg}
                          </span>
                          <ul>
                            {Object.keys(err.response.data.errors).map(
                              (key) => {
                                return (
                                  <li>
                                    <span style={{ fontSize: 11 }}>
                                      {err.response.data.errors[key]}
                                    </span>
                                  </li>
                                );
                              }
                            )}
                          </ul>
                        </>
                      );
                    }
                  }}
                  validateOnChange={true}
                  validate={(values) => {
                    const errors = {};
                    if (!values.name_ar) {
                      errors.name_ar = t("crud.errors.required");
                    }

                    if (!values.name_en) {
                      errors.name_en = t("crud.errors.required");
                    }

                    if (!values.description_ar) {
                      errors.description_ar = t("crud.errors.required");
                    }

                    if (!values.description_en) {
                      errors.description_en = t("crud.errors.required");
                    }

                    return errors;
                  }}
                >
                  {({
                    values,
                    errors,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                    handleBlur,
                    setFieldValue,
                    validateForm,
                  }) => (
                    <form
                      onSubmit={handleSubmit}
                      className={classes["manage-specialties__form"]}
                    >
                      <MainBoxHead
                        title={
                          type === "create"
                            ? t("specialties_manage.add_new_specialties")
                            : t("specialties_manage.edit_new_specialties")
                        }
                      />
                      <div
                        className={`row ${classes["manage-specialties__form__fields"]}`}
                      >
                        <div
                          className={`${classes["manage-specialties__form__field"]} col-md-6 col-12`}
                        >
                          <Input
                            label={t("specialties_manage.specialty_name_ar")}
                            type="text"
                            name="name_ar"
                            placeholder={t(
                              "specialties_manage.specialty_name_ar"
                            )}
                            onChange={handleChange}
                            error={errors.name_ar}
                            value={values.name_ar}
                          />
                        </div>
                        <div
                          className={`${classes["manage-specialties__form__field"]} col-md-6 col-12`}
                        >
                          <Input
                            label={t("specialties_manage.specialty_name_en")}
                            type="text"
                            name="name_en"
                            placeholder={t(
                              "specialties_manage.specialty_name_en"
                            )}
                            onChange={handleChange}
                            error={errors.name_en}
                            value={values.name_en}
                          />
                        </div>
                        <div
                          className={`${classes["manage-specialties__form__field"]} col-12`}
                        >
                          <label htmlFor="description_ar">
                            {t("specialties_manage.specialty_description_ar")}
                          </label>
                          <SunEditor
                            placeholder={t(
                              "specialties_manage.specialty_description_ar"
                            )}
                            value={values.description_ar}
                            defaultValue={values.description_ar}
                            onChange={(e) => {
                              handleChange({
                                target: { name: "description_ar", value: e },
                              });
                            }}
                            setOptions={{
                              height: 150,
                              rtl: i18n.language === "ar" ? true : false,
                              buttonList: [
                                [
                                  "bold",
                                  "underline",
                                  "italic",
                                  "strike",
                                  "align",
                                  "horizontalRule",
                                  "list",
                                  "lineHeight",
                                  "codeView",
                                ],
                              ],
                            }}
                            setDefaultStyle="font-family: 'Montserrat', sans-serif; font-size: 16px;"
                          />
                          {errors.description_ar && (
                            <p
                              className={`form-input-error-space ${classes["manage-specialties__form-error"]}`}
                            >
                              {errors.description_ar}
                            </p>
                          )}
                        </div>
                        <div
                          className={`${classes["manage-specialties__form__field"]} col-12`}
                        >
                          <label htmlFor="description_en">
                            {t("specialties_manage.specialty_description_en")}
                          </label>
                          <SunEditor
                            placeholder={t(
                              "specialties_manage.specialty_description_en"
                            )}
                            value={values.description_en}
                            defaultValue={values.description_en}
                            onChange={(e) => {
                              handleChange({
                                target: { name: "description_en", value: e },
                              });
                            }}
                            setOptions={{
                              height: 150,
                              rtl: i18n.language === "ar" ? true : false,
                              buttonList: [
                                [
                                  "bold",
                                  "underline",
                                  "italic",
                                  "strike",
                                  "align",
                                  "horizontalRule",
                                  "list",
                                  "lineHeight",
                                  "codeView",
                                ],
                              ],
                            }}
                            setDefaultStyle="font-family: 'Montserrat', sans-serif; font-size: 16px;"
                          />
                          {errors.description_en && (
                            <p
                              className={`form-input-error-space ${classes["manage-specialties__form-error"]}`}
                            >
                              {errors.description_en}
                            </p>
                          )}
                        </div>
                        <div
                          className={`${classes["manage-specialties__form__field"]}  col-12`}
                        >
                          <InputFile
                            name="image"
                            type="document"
                            accept={"image/*,"}
                            documentWithoutTitle={true}
                            label={t("category_Icon")}
                            placeholder={t("category_Icon")}
                            setFieldValue={setFieldValue}
                            value={values.image}
                            error={errors.image}
                          />
                        </div>
                        <ManageSpecialtiesAction
                          submitAction={handleSubmit}
                          isSubmitting={isSubmitting}
                          text={
                            type === "create"
                              ? t("general.submit")
                              : t("general.save")
                          }
                        />
                      </div>
                    </form>
                  )}
                </Formik>
              </MainBox>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManageSpecialties;

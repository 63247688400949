import { useEffect, useRef, useState } from "react";
import styled from "styled-components";

// تعريف ClickableSVG خارج المكون
const ClickableSVG = styled.svg`
  width: 100%;
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
`;

export default function HotSpotQuestionView({ question, values, setFieldValue }) {
  const img = useRef(null);
  const [screenX, setScreenX] = useState(0);
  const [screenY, setScreenY] = useState(0);
  const [screenWidth, setScreenWidth] = useState(0);
  const [screenHeight, setScreenHeight] = useState(0);
  const [display, setDisplay] = useState("none");
  // const [linkURL, setLinkURL] = useState("");

  function imageClick(e) {
    var x = e.nativeEvent.offsetX;
    var y = e.nativeEvent.offsetY;
    setScreenX(x);
    setScreenY(y);
    setDisplay("block");

    setFieldValue("answer", [(x / screenWidth) * 100, (y / screenHeight) * 100]);
  }

  useEffect(() => {
    if (!img.current) return;
    const resizeObserver = new ResizeObserver(() => {
      if (img.current) {
        setScreenWidth(img.current.getBoundingClientRect().width);
        setScreenHeight(img.current.getBoundingClientRect().height);
      }
    });
    resizeObserver.observe(img.current);
    return () => resizeObserver.disconnect(); // clean up
  }, []);

  useEffect(() => {
    if (screenWidth && screenHeight && values.answer) {
      let xp = parseFloat(values.answer[0]);
      let yp = parseFloat(values.answer[1]);
      setScreenX((xp * screenWidth) / 100);
      setScreenY((yp * screenHeight) / 100);
    }
  }, [values.answer, screenWidth, screenHeight]);

  // useEffect(() => {
  //   const fetchImage = async () => {
  //     const response = await fetch(question.link, {
  //       method: "GET",
  //       headers: {
  //         Authorization: `Bearer ${localStorage.getItem("token") ?? localStorage.getItem("guest_token")}`,
  //       },
  //     });
  //     const blob = await response.blob();
  //     setLinkURL(URL.createObjectURL(blob));
  //   };

  //   if (question.link) fetchImage();
  // }, [question.link]);

  return (
    <>
      <div
        ref={img}
        style={{
          maxWidth: "100%",
          maxHeight: "644px",
          aspectRatio: question?.media ? `${question?.media?.size[0]}/${question?.media?.size[1]}` : "",
        }}
      >
        <ClickableSVG 
          onClick={imageClick}
          style={{
            backgroundImage: `url(${question?.media?.link})`,
          }}>
          <circle
            cx={screenX}
            cy={screenY}
            r="20"
            stroke="#126e77"
            strokeWidth="4"
            fill="none"
            style={{
              zIndex: "1",
              display: display,
            }}
          />
        </ClickableSVG>
      </div>
    </>
  );
}

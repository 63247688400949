import { Formik } from "formik";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import DeleteIcon from "../../assets/icons/delete.svg";
import Checkbox from "../../Modules/Shared/Components/Checkbox/Checkbox";
import CustomButton from "../../Modules/Shared/Components/CustomButton/CustomButton";
import Input from "../../Modules/Shared/Components/Input/Input";
import { LessonContentContext } from "../../Providers/LessonContentProvider";
import { uploadFile } from "../../Services/api/files/filesProvider";

function AddTextToLessonModal(props) {
  require("./AddTextToLessonModal.css");
  const lessonContentContext = useContext(LessonContentContext);

  const { t, i18n } = useTranslation();
  const { id } = useParams();

  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [isLocalSubmitting, setIsLocalSubmitting] = useState(false);
  const [refreshEditor, setRefreshEditor] = useState(false);

  const handleImageUploadBefore = (files, info, uploadHandler) => {
    const formData = new FormData();
    formData.append("file", files[0]);
    formData.append("collection_name", "exhibitions");
    formData.append("key", "file");
    uploadFile(formData)
      .then((res) => {
        const response = {
          result: [
            {
              url: `${process.env.REACT_APP_SERVER_PATH}storage/exhibitions/${res.data.media.file_path}`,
              name: files[0].name,
              size: files[0].size,
            },
          ],
        };
        uploadHandler(response, info);
      })
      .catch((error) => {
        toast.error(
          <>
            <span style={{ fontSize: 13, fontWeight: "bold", display: "block" }}>{error?.response?.data?.msg}</span>
            {error?.response?.data?.errors && (
              <ul>
                {Object.keys(error.response.data.errors).map((key) => {
                  return (
                    <li>
                      <span style={{ fontSize: 11 }}>{error.response.data.errors[key]}</span>
                    </li>
                  );
                })}
              </ul>
            )}
          </>
        );
        setRefreshEditor(true);
      });
  };

  useEffect(() => {
    if (refreshEditor) {
      setRefreshEditor(false);
    }
  }, [refreshEditor]);

  return (
    <>
      <div
        className="add_text_modal"
        onClick={(event) => {
          event.stopPropagation();
        }}
      >
        <div className="add_text_modal_header">
          <h2 className="add_text_modal_title">{props.title}</h2>
          <div className="add_text_modal_close" onClick={props.dismissModal}>
            <img src={DeleteIcon} alt="close" />
          </div>
        </div>
        <Formik
          initialValues={{
            title: props?.data?.title || "",
            content: props?.data?.content || "",
            // include_achivement_per: props?.data?.include_achivement_per
            //   ? props?.data?.include_achivement_per
            //   : false,
            include_view_per: props?.data?.include_view_per ? props?.data?.include_view_per : false,
            lesson_id: id,
            type: "text",
            content_type: "content",
            ...(props.data ? { _method: "put" } : {}),
          }}
          onSubmit={async (values, { setSubmitting }) => {
            setSubmitting(true);
            setIsLocalSubmitting(true);
            let formData = new FormData();
            Object.keys(values).forEach((field) => {
              formData.append(field, values[field]);
            });

            formData.append("include_view_per", values.include_view_per ? 1 : 0);
            formData.append("parent_id", null);

            try {
              props.data
                ? await lessonContentContext.editContentActivity(formData, props?.data?.id)
                : await lessonContentContext.addContent(formData);
              await lessonContentContext.fetchLessonContent(id);
              props.dismissModal();
            } catch (error) {
              setIsLocalSubmitting(false);
              setSubmitting(false);
              return;
            }
          }}
          validate={(values) => {
            setHasSubmitted(true);
            const errors = {};
            if (!values.title) {
              errors.title = t("crud.errors.required");
            }

            if (!values.content) {
              errors.content = t("crud.errors.required");
            }

            return errors;
          }}
          validateOnChange={hasSubmitted}
        >
          {({ setFieldValue, values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
            <form onSubmit={handleSubmit} className="add_text_modal_form">
              <div className="add_text_modal_form_fields">
                <Input
                  type="text"
                  placeholder={t("trainer.course.lesson.crud.attachmentLabel")}
                  label={t("trainer.course.lesson.attachmentLabel")}
                  name="title"
                  onChange={handleChange}
                  value={values.title}
                  error={errors.title}
                  required
                />
                <div style={{ display: "flex", gap: "20px" }}>
                  <Checkbox
                    name="include_view_per"
                    label={t("include_view_per")}
                    onChange={(event) => {
                      setFieldValue("include_view_per", event.target.checked ? 1 : 0);
                    }}
                    checked={values.include_view_per === true || values.include_view_per === 1}
                    error={errors.include_view_per}
                    value={values.include_view_per === true || values.include_view_per === 1}
                  />
                </div>
                <div>
                  <label htmlFor="content">{t("trainer.course.lesson.attachmentDescription")}</label>
                  {!refreshEditor && (
                    <SunEditor
                      value={values.content}
                      defaultValue={values.content}
                      name="content"
                      onImageUploadBefore={handleImageUploadBefore}
                      onChange={(content) => {
                        setFieldValue("content", content);
                      }}
                      setDefaultStyle="font-family: 'Montserrat', sans-serif; font-size: 16px;"
                      setOptions={{
                        height: 200,
                        rtl: i18n.language === "ar" ? true : false,
                        buttonList: [
                          ["undo", "redo"],
                          ["removeFormat"],
                          ["outdent", "indent"],
                          ["showBlocks", "codeView"],
                          ["align"],
                          ["table", "link", "image", "video", "audio"],
                        ],
                      }}
                    />
                  )}
                </div>
              </div>
              <div className="add_text_actions">
                <CustomButton
                  type="submit"
                  value={t("sendNow")}
                  loading={isSubmitting}
                  disable={isSubmitting}
                  colors="#036c77"
                />
              </div>
            </form>
          )}
        </Formik>
      </div>
    </>
  );
}

export default AddTextToLessonModal;

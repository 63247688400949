import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { useHistory } from "react-router";
import { getAdHomepage } from "../../../Services/api/HomeCoursesProvider";
import MainSectionCarousel from "../../Shared/Components/MainSectionCarousel/MainSectionCarousel";
import SkeletonSpinnerOverlay from "../../Shared/Components/Spinner/SkeletonSpinnerOverlay";
import classes from "./CarosalHomePage.module.css";
import CaroselSlide from "./CaroselSlide";

const CarosalHomePage = () => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const [adList, setAdList] = useState([]);
  const [adListLoading, setAdListLoading] = useState(false);

  useEffect(() => {
    // setTrainersLoading(true);

    const apiCall = getAdHomepage;

    apiCall()
      .then((res) => {
        if (res.status && res.status === 200) {
          // console.log(res);
          setAdList(res.data.data);
          setAdListLoading(true);
        }
      })
      .catch((err) => {
        console.log(err);
        setAdListLoading(true);
      });
  }, []);

  return (
    <>
      {adListLoading ? (
        <div className={classes.text_img}>
          {adList.length === 0 ? (
            <></>
          ) : (
            <>
              {adList.length === 1 ? (
                <>
                  {adList.map((ad) => (
                    <>
                      <div
                        className={classes["main"]}
                        style={{
                          backgroundImage: `url(${encodeURI(
                            ad?.[i18n.language === "ar" ? "img_desktop_ar" : "img_desktop_en"]
                          )})`,
                          cursor: ad?.url ? "pointer" : "",
                        }}
                        onClick={() => (ad?.url ? window.open(ad?.url, "_blank") : null)}
                      >
                        <div className={classes.backdropDiv}>
                          <div className={classes["main__wrapper"]}>
                            {ad?.show_content !== "0" && (
                              <div className={classes["main__content"]}>
                                <h2 className={classes["main__content-title"]}>{ad?.title}</h2>
                                <p
                                  className={classes["main__content-desc"]}
                                  style={ad?.description?.length > 710 ? { fontSize: "14px" } : {}}
                                >
                                  {ad?.description}
                                </p>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div
                        className={classes["main__mobile"]}
                        style={{
                          backgroundImage: `url(${encodeURI(
                            ad?.[
                              i18n.language === "ar"
                                ? ad?.img_mobile_ar
                                  ? "img_mobile_ar"
                                  : "img_desktop_ar"
                                : ad?.img_mobile_en
                                ? "img_mobile_en"
                                : "img_desktop_en"
                            ]
                          )})`,
                          cursor: ad?.url ? "pointer" : "",
                        }}
                        onClick={() => (ad?.url ? window.open(ad?.url, "_blank") : null)}
                      >
                        <div className={classes.backdropDiv}>
                          <div className={classes["main__wrapper"]}>
                            {ad?.show_content !== "0" && (
                              <div className={classes["main__content"]}>
                                <h2 className={classes["main__content-title"]}>{ad?.title}</h2>
                                <p
                                  className={classes["main__content-desc"]}
                                  style={ad?.description?.length > 710 ? { fontSize: "14px" } : {}}
                                >
                                  {ad?.description}
                                </p>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </>
                  ))}
                </>
              ) : (
                <>
                  <MainSectionCarousel>
                    {adList.map((ad, index) => (
                      <CaroselSlide ad={ad} adListLoading={adListLoading} key={index} id={ad.id} />
                    ))}
                  </MainSectionCarousel>
                </>
              )}
            </>
          )}
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "20px",
          }}
        >
          <div
            style={{
              height: "600px",
              width: "90%",
            }}
          >
            <SkeletonSpinnerOverlay backdrop={true} skeletonWidth="100" skeletonHight="100" />
          </div>
          {/* <div
            style={{
              width: "200px",
            }}
          >
            <SkeletonSpinnerOverlay backdrop={true} skeletonWidth="100" />
            <SkeletonSpinnerOverlay backdrop={true} skeletonWidth="100" />
          </div> */}
        </div>
      )}
    </>
  );
};

export default CarosalHomePage;

import { Pagination } from "@mui/material";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import clearEmptyProp from "../../../../../Helpers/ClearEmptyProp";
import { decrypt } from "../../../../../Helpers/Secret";
import { fetchUserWalletById } from "../../../../../Redux/Actions/wallet/wallet.action";
import { walletConstants } from "../../../../../Redux/Constants/wallet/wallet.constants";
import { exportUserWalletDetails } from "../../../../../Services/api/Roles/RolesProvider";
import HomeIcon from "../../../../../assets/icons/home.svg";
import { ReactComponent as ReopenIcon } from "../../../../../assets/icons/reopen.svg";
import { utcToLocal } from "../../../../../utils/utcToLocal";
import Breadcrumb from "../../../../Shared/Components/Breadcrumb/Breadcrumb";
import MainBox from "../../../../Shared/Components/MainBox/MainBox";
import MainBoxHead from "../../../../Shared/Components/MainBoxHead/MainBoxHead";
import { BasicModal } from "../../../../Shared/Components/Modal/BasicModal";
import SkeletonCard from "../../../../Shared/Components/Spinner/SkeletonCard";
import SkeletonCardOverlay from "../../../../Shared/Components/Spinner/SkeletonCardOverlay";
import WalletTransactionModal from "./WalletTransactionModal";
import classes from "./walletDetailsList.module.css";
import WalletDetailsListFilter from "./walletDetailsListFilter";
import WalletDetailsListTable from "./walletDetailsListTable";
import { filterActions } from "./walletDetailsListTableActions";

function WalletDetails(props) {
  require("./walletDetails.css");

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { userWallet, userWalletDashboard, userWalletMeta, userWalletTransactions, isLoading, isDashboardLoading } =
    useSelector((state) => state.userWalletReducer);
  const walletTransactionRef = useRef();
  const [showWalletTransactionModal, setShowWalletTransactionModal] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [isResetApplied, setIsResetApplied] = useState(false);
  const [isExportLoading, setIsExportLoading] = useState(false);
  const [transactionHappend, setTransactionHappend] = useState(false);
  const [dataToExport, setDataToExport] = useState([]);
  const [userId, setUserId] = useState(userWallet.id);
  const [isDashboardFetch, setIsDashboardFetch] = useState(false);
  const [filterData, setFilterData] = useState({
    payable_type: "",
    payable_name: "",
    wallet_transaction_type: "",
    status: "",
    course_id: "",
    program_id: "",
    exhibition_id: "",
    dashboard: "",
  });

  const statistics = [
    {
      label: t("wallet.label.WDcurrentamount"),
      value: userWalletDashboard?.total,
      color: "#046C77",
      icon: (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 67.86 67.86">
          <path
            id="check-circle_4_"
            data-name="check-circle (4)"
            d="M51.5,25.725a2.825,2.825,0,0,1-.04,4L38.946,42.008a11.251,11.251,0,0,1-15.766.062L17.81,36.792a2.827,2.827,0,0,1,3.964-4.032l5.352,5.262a5.618,5.618,0,0,0,7.863-.051L47.5,25.688a2.825,2.825,0,0,1,4,.037ZM67.86,33.93A33.93,33.93,0,1,1,33.93,0,33.968,33.968,0,0,1,67.86,33.93Zm-5.655,0A28.275,28.275,0,1,0,33.93,62.205,28.307,28.307,0,0,0,62.205,33.93Z"
            fill="#fff"
          />
        </svg>
      ),
    },
    {
      label: t("wallet.label.WDdeposits"),
      value: userWalletDashboard?.credit,
      color: "#046C77dd",
      icon: (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 67.86 67.86">
          <path
            id="add_1_"
            data-name="add (1)"
            d="M33.93,0A33.93,33.93,0,1,0,67.86,33.93,33.93,33.93,0,0,0,33.93,0Zm0,62.205A28.275,28.275,0,1,1,62.205,33.93,28.275,28.275,0,0,1,33.93,62.205ZM48.068,33.93a2.828,2.828,0,0,1-2.828,2.828H36.758V45.24a2.828,2.828,0,1,1-5.655,0V36.758H22.62a2.828,2.828,0,0,1,0-5.655H31.1V22.62a2.828,2.828,0,1,1,5.655,0V31.1H45.24A2.828,2.828,0,0,1,48.068,33.93Z"
            fill="#fff"
          />
        </svg>
      ),
    },
    {
      label: t("wallet.label.WDwithdrawal"),
      value: userWalletDashboard?.payment,
      color: "#046C77cc",
      icon: (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 67.857 67.863">
          <path
            id="user-pen"
            d="M25.448,33.93A16.965,16.965,0,1,0,8.483,16.965,16.982,16.982,0,0,0,25.448,33.93Zm0-28.275a11.31,11.31,0,1,1-11.31,11.31A11.321,11.321,0,0,1,25.448,5.655ZM30.4,45.862A19.815,19.815,0,0,0,5.655,65.033a2.828,2.828,0,1,1-5.655,0A25.465,25.465,0,0,1,31.81,40.388,2.828,2.828,0,0,1,30.4,45.865ZM65.375,33.588a8.684,8.684,0,0,0-12,0L34.414,52.552a11.242,11.242,0,0,0-3.314,8v4.484a2.829,2.829,0,0,0,2.828,2.828h4.484a11.235,11.235,0,0,0,8-3.314L65.372,45.585a8.485,8.485,0,0,0,0-12Zm-4,8L42.407,60.548a5.607,5.607,0,0,1-4,1.657H36.752V60.548a5.617,5.617,0,0,1,1.657-4L57.376,37.586a2.893,2.893,0,0,1,4,0,2.826,2.826,0,0,1,0,4Z"
            fill="#fff"
          />
        </svg>
      ),
    },
    {
      label: t("wallet.label.WDrecovery"),
      value: userWalletDashboard?.refund,
      color: "#046C77bb",
      icon: <ReopenIcon className="tw-h-full tw-w-full tw-fill-white" />,
    },
  ];

  const resetFilterHandler = () => {
    setFilterData({
      payable_type: "",
      payable_name: "",
      wallet_transaction_type: "",
      status: "",
      course_id: "",
      program_id: "",
      exhibition_id: "",
      dashboard: "",
    });
    setIsResetApplied(true);
  };

  const getFilterData = (isTransaction, withDashboard = true) => {
    const filter = {};
    filterData.status && (filter.status = filterData.status);
    (!Object.keys(userWalletDashboard || {}).length || isTransaction || userId !== id) &&
      withDashboard &&
      (filter.dashboard = "1");
    filterData.wallet_transaction_type && (filter.wallet_transaction_type = filterData.wallet_transaction_type);
    filterData.payable_type && (filter.payable_type = filterData.payable_type);
    if (filterData.payable_type === "course") {
      filterData.course_id && (filter.course_id = filterData.course_id);
    } else if (filterData.payable_type === "program_payment") {
      filterData.program_id && (filter.program_id = filterData.program_id);
    } else if (filterData.payable_type === "exhibition_visit") {
      filterData.exhibition_id && (filter.exhibition_id = filterData.exhibition_id);
    } else {
      filterData.payable_name && (filter.payable_name = filterData.payable_name);
    }

    return filter;
  };

  // const getUserWalletHandler = () => {
  //   setLoading(true);
  //   const filter = getFilterData();
  //   setDataToExport(filter);
  //   getPayments({ page: currentPage, perPage: 10, filter })
  //     .then((res) => {
  //       if (res.status && res.status === 200 && res.data.status) {
  //         setWalletList(res.data.data.payments);
  //         setWalletMeta(res.data.data.meta);
  //         setLoading(false);
  //       }
  //     })
  //     .catch((err) => {
  //       toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{err.response.data.msg}</span>);
  //       setLoading(false);
  //     });
  // };

  const getUserWalletHandler = (isTransaction) => {
    const filter = getFilterData(isTransaction);
    setDataToExport(filter);
    if (isTransaction) {
      setTransactionHappend(true);
    }
    dispatch(fetchUserWalletById({ id, page: currentPage, perPage: 10, filter })).then(() => {
      setTransactionHappend(false);
    });
  };
  useEffect(() => {
    if (!userWalletDashboard && !isDashboardFetch) {
      setIsDashboardFetch(true);
      getUserWalletHandler();
    }
    if (isResetApplied) {
      setIsResetApplied(false);
    }
  }, [currentPage, isResetApplied, userWalletDashboard]);
  useEffect(() => {
    dispatch({
      type: walletConstants.RESET_DASHBOARD,
    });
  }, []);

  useEffect(() => {
    dispatch(fetchUserWalletById({ id, page: currentPage, perPage: 10, filter: getFilterData(false, false) }));
  }, [id, currentPage]);

  const breadcrumbList = [
    {
      id: "home",
      page: <img src={HomeIcon} alt="" />,
      pagePath: "/",
    },
    {
      id: "home",
      page: t("wallet.label.wallet"),
      pagePath: decrypt(localStorage.getItem("type")) == "accountant" ? "/accountant" : "/admin" + "/users-wallet",
    },
    {
      id: "walletDetails",
      page: t("wallet.label.walletDetails"),
      active: true,
    },
  ];

  const showFilterHandler = () => {
    setShowFilter((prev) => !prev);
  };

  const changeHandler = (e) => {
    const { name, value } = e.target;
    setFilterData({
      ...filterData,
      [name]: value,
      [name === "major" ? "department" : ""]: "",
    });
  };

  const resetHandler = (name) => {
    setFilterData({ ...filterData, [name]: "" });
  };

  const exportHandler = async (type) => {
    setIsExportLoading(true);
    try {
      await exportUserWalletDetails(clearEmptyProp(dataToExport), type, id);
      setIsExportLoading(false);
    } catch (e) {
      setIsExportLoading(false);
      toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{t("failed_fetching")}</span>);
    }
  };

  const openWalletTransactionModal = () => {
    setShowWalletTransactionModal(true);
    walletTransactionRef.current.showModal();
  };

  const closeWalletTransactionModal = () => {
    setShowWalletTransactionModal(false);
    walletTransactionRef.current.dismissModal();
  };
  console.log(userWalletDashboard);

  return (
    <div className={classes["system-users-list"]}>
      {isExportLoading && <SkeletonCardOverlay skeletonWidth="100" />}
      <div className="container-fluid">
        <div className={classes["system-users-list__container"]}>
          <div className="row" id="content-header-bar">
            <div className="col-12">
              <Breadcrumb list={breadcrumbList} />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              {isLoading ? (
                <div
                  className="mt-5"
                  style={{
                    height: "25vh",
                  }}
                >
                  <SkeletonCard />
                </div>
              ) : (
                <MainBox>
                  <div>
                    {t("wallet.label.WDusername")}: {userWallet.name}
                  </div>
                  <div>
                    {t("wallet.label.WDjobtitle")}:{" "}
                    {userWallet?.roles
                      ?.map((role, index, array) => role?.label + (index === array.length - 1 ? "" : " | "))
                      .join("")
                      .trim()}
                  </div>
                  <div>
                    {t("wallet.label.WDregdate")}: {moment(userWallet.created_at).format("YYYY-MM-DD")}{" "}
                    {utcToLocal(userWallet.created_at)}
                  </div>
                  <div>
                    {t("wallet.label.iban")}: {userWallet?.iban || "-"}
                  </div>
                </MainBox>
              )}
            </div>
          </div>
          {!userWalletDashboard || transactionHappend ? (
            <div
              className="mt-5"
              style={{
                height: "25vh",
              }}
            >
              <SkeletonCard />
            </div>
          ) : (
            <div
              style={{
                width: "98%",
                margin: "auto",
              }}
              className="tw-bg-white tw-rounded tw-shadow tw-p-8 tw-space-y-4 container-fluid"
            >
              <div className="tw-text-teal-700 tw-text-2xl">{t("general.statistics")}</div>
              <div className="tw-grid tw-grid-cols-1 sm:tw-grid-cols-2 lg:tw-grid-cols-4 tw-gap-4">
                {statistics.map((item) => (
                  <div
                    className="tw-flex tw-items-center tw-space-s-4 tw-p-4 tw-text-white tw-rounded"
                    style={{ backgroundColor: item.color }}
                  >
                    <div className="tw-h-10 tw-w-10">{item.icon}</div>
                    <div>
                      <div className="tw-text-2xl">{item.value}</div>
                      <div className="tw-text-sm">{item.label}</div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
          <div className="row">
            <div className="col-12">
              <MainBox>
                <MainBoxHead
                  title={t("wallet.label.walletDetails")}
                  actions={filterActions({
                    t,
                    walletTransaction: openWalletTransactionModal,
                    showFilter: !isLoading && showFilterHandler,
                    exportPdf: () => {
                      exportHandler("pdf");
                    },
                    exportExcel: () => {
                      exportHandler("xlsx");
                    },
                  })}
                />
                {showFilter && (
                  <WalletDetailsListFilter
                    filterData={filterData}
                    changeHandler={changeHandler}
                    resetHandler={resetHandler}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    filterHandler={getUserWalletHandler}
                    resetFilterHandler={resetFilterHandler}
                  />
                )}
                <WalletDetailsListTable
                  walletList={userWalletTransactions}
                  isLoading={isLoading}
                  getWalletList={getUserWalletHandler}
                />
                <Pagination
                  count={userWalletMeta?.totalPages || 0}
                  showFirstButton
                  showLastButton
                  variant="outlined"
                  shape="rounded"
                  className="main-pagination"
                  page={currentPage}
                  onChange={(e, page) => {
                    setCurrentPage(page);
                  }}
                />
              </MainBox>
            </div>
          </div>
        </div>
      </div>
      <BasicModal ref={walletTransactionRef}>
        {showWalletTransactionModal && (
          <WalletTransactionModal
            closeWalletTransactionModal={closeWalletTransactionModal}
            id={id}
            fetchUserWalletHandler={getUserWalletHandler}
          />
        )}
      </BasicModal>
    </div>
  );
}

export default WalletDetails;
